<template>
    <div ref="watermarkRef" class="watermark-overlay"></div>
  </template>
  
  
  <script>
  import { watchEffect, ref, onMounted, onUnmounted, watch } from 'vue';
  

  export default {
  components: {},
  props: {
    content: {
      type: Array,
      required: true
    },
    fontSize: {
      type: Number,
      default: 14
    },
    density: {
      type: Number,
      default: 1
    },
    opacity: {
      type: Number,
      default: 0.12
    },
    rotate: {
      type: Number,
      default: -15
    },
    color: {
      type: String,
      default: 'rgba(128, 128, 128, 0.6)'
    }
  },
  setup(props) {
    const watermarkRef = ref(null)
    const base64Url = ref('')
    let observer = null
    let resizeTimer = null

    // 计算实际间距
    const actualGap = ref(180 / props.density)

    // 生成水印图片
    const generateWatermark = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const ratio = window.devicePixelRatio || 1
      
      // 动态计算画布尺寸
      const textHeight = props.fontSize * ratio * 1.5
      const canvasWidth = actualGap.value * ratio * 3
      const canvasHeight = actualGap.value * ratio * 2

      canvas.width = canvasWidth
      canvas.height = canvasHeight

      // 设置绘制属性
      ctx.font = `${props.fontSize * ratio}px Microsoft Yahei`
      ctx.fillStyle = props.color
      ctx.globalAlpha = props.opacity
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'

      // 坐标系变换
      ctx.translate(canvasWidth/2, canvasHeight/2)
      ctx.rotate((Math.PI / 180) * props.rotate)

      // 多行文本绘制
      props.content.forEach((text, index) => {
        ctx.fillText(
          text,
          0,
          index * textHeight - (textHeight * (props.content.length - 1)) / 2
        )
      })

      base64Url.value = canvas.toDataURL()
    }

    // 应用水印样式
    const applyWatermark = () => {
      if (!watermarkRef.value) return
      
      watermarkRef.value.style.backgroundImage = `url("${base64Url.value}")`
      watermarkRef.value.style.backgroundSize = `${actualGap.value * 3}px ${actualGap.value * 2}px`
    }

    // 防篡改监听
    const initMutationObserver = () => {
      observer = new MutationObserver(() => {
        if (!watermarkRef.value?.isConnected) {
          applyWatermark()
        }
      })

      observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true
      })
    }

    // 响应参数变化
    watchEffect(() => {
      actualGap.value = 180 / props.density
      generateWatermark()
      applyWatermark()
    })

    // 窗口变化防抖处理
    const handleResize = () => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        generateWatermark()
        applyWatermark()
      }, 300)
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize)
      initMutationObserver()
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
      observer?.disconnect()
    })

    return {
      watermarkRef
    }
  }
}
  </script>
  
  <style scoped>
  .watermark-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 9999;
    background-repeat: repeat;
    user-select: none;
    -webkit-user-drag: none;
  }
  </style>
  
  