export default {
  "TOP": "TOP"

  , "Head.Admin": "Admin"
  , "Head.Mine": "Mine"
  , "Head.Lecturer": "Lecturer"
  , "Head.Logout": "Logout"
  , "Head.Home": "Home"
  , "Head.SelecLanguage": "Language"

  , "Home.LearningDuration": "Learning duration"
  , "Home.Tasks": "My Tasks"
  , "Home.Course": "Course"
  , "Home.Project": "Project"
  , "Home.Exam": "Exam"
  , "Home.SeeMore": "More"
  , "Home.todoTips": "begin in a minute/Ongoing exams, face-to-face lectures, and live broadcasts"
  , "Home.pcs": "pcs"
  , "Home.reload": "reload"
  , "Home.init": "Home page data initialization in progress, please wait"
  , "Home.LearningDurationRanking": "Learning Duration Ranking"
  , "Home.AllRankings": "All Rankings"
  , "Home.TodayLeaderboard": "Today's leaderboard"
  , "Home.WeekLeaderboard": "This week's leaderboard"
  , "Home.MonthLeaderboard": "This month's leaderboard"
  , "Home.WaitingInVain": "Waiting in vain"
  , "Home.inProgress": "in progress"
  , "Home.beginInAMinute": "begin in a minute"
  , "Home.ExamFrequency": "Exam num"
  , "Home.noLimit": "Unlimit"
  , "Home.NumberOfParticipants": "number"
  , "Home.FromEnd": "From End"
  , "Home.FromStart": "From Start"
  , "Home.day": "day"
  , "Home.EnterImmediately": "Enter immediately"
  , "Home.NoIntroduction": "No Introduction"
  , "Home.NoLecturer": "No Lecturer"
  , "Home.introduce": "introduce"
  , "Home.None": "None"
  , "Home.NoContent": "no content"
  , "Home.Newly": "newly"
  , "Home.NoData": "no more content"
  , XB_PlatformSwitching: "platform switching",
  XB_LarkLogin: "Lark login",
  XB_DingTalkLogin: "Ding Talk login",
  XB_WeComLogin: "WeCom login",
  XB_WeComH5Login: "WeCom H5 login",
  XB_WechatLogin: "wechat login",
  XB_PersonalRegistration: "personal registration",
  XB_ExamAnswers: "exam answers",
  XB_LearnMapDetails: "learn map details",
  XB_LiveBroadcastRoom: "live broadcast room",
  XB_UserAgreement: "user agreement",
  XB_ErrorPage: "error page",
  XB_LoginPrompt: "login prompt",
  XB_Preview: "preview",
  XB_Unauthorized: "unauthorized",
  XB_Study: "Study",
  XB_Resource: "RC",
  XB_ResourceCenter: "Resource",
  XB_MyData: "My data",
  XB_NotFinishedLearning: "Not finished learning",
  XB_PersonalCenter: "My center",
  XB_PopularKnowledge: "Popular knowledge",
  XB_LatestCourses: "Latest courses",
  XB_LearningTask: "Learning task",
  XB_KnowledgeBase: "Knowledge",
  XB_KnowledgeDetail: "knowledge details",
  XB_QuestionnaireSurvey: "Survey",
  XB_CourseCenter: "Course",
  XB_PagesNumber: "Pages",
  XB_ClearCache: "Clear cache",
  XB_StudyHoursRanking: "Study ranking",
  XB_HasEnded: "Has ended",
  XB_Search: "Search",
  XB_My_CompleteCourse: "Complete course",
  XB_My_GetCredit: "Get credit",
  XB_Home_LastLearned: "Last time I learned",
  XB_Home_EnterLearning: "Enter learning",
  XB_Home_SeeMore: "See more",
  XB_More: "more",
  XB_Home_ClickView: "Click to view",
  XB_Home_Uptime: "Uptime",
  XB_Home_Instructor: "Instructor",
  XB_Home_Browse: "Browse",
  XB_Home_ReadingVolume: "Reading volume",
  XB_Home_To: "to",
  XB_LP_Stage: "Stage {0}",
  XB_LP_StudentStudiedTogether: "A total of {0} students study together",
  XB_LP_MyRankingIs: "My ranking is {0}",
  XB_LP_DefeatedTheStudents: "Defeated the students of {0}",
  XB_PleaseAnswerTheQuestionFirst:
    "Please answer the following questions and continue to study",
  XB_AnswerTheQuestionFirst:
    "You have been studying for {0} minutes. Answer the following questions and continue studying",
  XB_WarmTip: "Warm tip",
  XB_ContinueToWatchTheCourse:
    "Congratulations on your correct answer. Please continue to watch the course",
  XB_WrongSwitchNextQuestion:
    "This question is wrong, please click OK to switch to the next question",
  XB_NumberOfParticipants: "Number of participants",
  XB_NumberOfLearners: "Number of learners",
  XB_Reward: "Tip",
  XB_GetRewardedScores: "Get rewarded scores",
  XB_InTotal: "{0} in total",
  XB_AvailableContent: "Available content",
  XB_AvailableHours: "Available hours",
  XB_GetCredits: "Get credits",
  XB_QuestionBank: "Question bank",
  XB_Anonymous: "anonymous",
  XB_Category: "Category",
  DR_Error_NoAuthorized: "Sorry, you have no right to take the exam",
  DR_Error_NoAuthorizedByExercise:
    "Sorry, you have no right to take the exercise",
  XB_EntryType: "Entry type",
  XB_Type: "Type",
  XB_LearningDuration: "Learning duration",
  XB_ThematicDiscussionGroup: "Thematic discussion group",
  XB_Personnel: "Personnel",
  XB_EnterDiscussionGroup: "Enter the discussion group",
  XB_ForwardYourAnswer: "Looking forward to your answer",
  XB_Reply: "Reply",
  XB_LetMeSay: "Let me say a few words ...",
  XB_MyTotalIntegral: "My total integral",
  XB_EarnPoints: "Complete the task and get points",
  XB_AcquisitionMethod: "Rule",
  XB_ImageUpload: "Image upload",
  XB_AllColleges: "All colleges",
  XB_IntroductionInstructions: "Introduction instructions",
  XB_MyPoints: "My points",
  XB_CommoditySurplus: "Commodity surplus",
  XB_HasExchanged: "Has been exchanged",
  XB_ExchangeQuantity: "Exchange quantity",
  XB_ConsumptionPoints: "Consumption points",
  XB_PeopleHaveJoined: "{0} people have joined",
  XB_Group_GroupShortTitle: "This group is still short of a title ~",
  XB_Group_DescribeThisGroup: "Come on, describe it to this group ...",
  XB_Group_TopicShortTitle: "This topic is still short of a title ~",
  XB_Group_DescribeThisTopic: "Come on, describe this topic ...",
  XB_Signup: "Sign-up",
  XB_ExamHasStarted: "The exam has started",
  XB_FaceSampling: "Face sampling",
  XB_Course_Lecturer: "Lecturer",
  XB_LearningSituation: "Learning situation",
  XB_Message: "Message",
  XB_TotalOf: "A total of {0}",
  Pub_Msg_DDM: "Are you sure to delete this member?",
  Pub_Msg_DCUAJd: "Are you sure to cancel administrator privilege?",
  Pub_Msg_DUUpA: "Are you sure the user has been upgraded to an administrator?",
  Lab_Group_SetAdministrator: "Setup administrator",
  Lab_Group_CancelAdministrator: "Cancel administrator",
  CM_Rule_ImageTopic: "Picture topic",
  LB_Group_ErrorMsg_TagDuplicate: "Duplicate label",
  LB_Group_ErrorMsg_DescriptionMissing: "Description cannot be empty",
  LB_Group_SaveTag: "Save label",
  LB_Group_CustomTag: "Custom label",
  XB_Group_ChooseTagMax: "Choose up to 5",
  XB_Group_ChooseLabel: "Choose label",
  XB_Exam_PendingApproval: "Pending approval",
  BN_Comm_Completed: "Complete",
  BN_Comm_Edit: "Edit",
  BN_Settings_CancelBinding: "Unbind",
  CM_Affirm: "Confirm",
  CM_All: "All",
  CM_AllCollections: "Favorites",
  CM_AlreadySignToday: "signed in",
  CM_Answer: "Answered",
  CM_Retract: "Retract",
  CM_Current: "Current",
  CM_Correct: "Correct",
  CM_Wrong: "Wrong",
  CM_Audite: "Whether audit",
  CM_Btnreset: "Reset",
  CM_Cancel: "Cancel",
  CM_CancelCollection: "Remove the Favorite !",
  CM_CancelCollectionFailure: "Fail to collect",
  CM_CancelFavoriteSuccess: "Unfavorite",
  CM_CancelRegistration: "Noncancelable registration!",
  CM_CancelRegistrationSuccessful: "Succeed in cancelling registration",
  CM_CCondition: "Condition",
  CM_Choose: "Elective",
  CM_SignInNow: "Sign in now",
  CM_CollectionFailure: "Fail to collect",
  CM_CollectionSuccess: "Collected",
  CM_Comment: "Comment",
  CM_Completed: "Completed",
  CM_Compressed: "Compressed files",
  CM_Confirm: "Confirm",
  CM_ConfirmPassword: "Confirm the new password",
  CM_ContentLengthLong: "Content length is too long",
  CM_ConversionForSuccessful: "Exchange accomplished",
  CM_CT: "Off-line training",
  CM_Curriculum: "Course",
  CM_CutNumber:
    "You are forced to hand in the paper because your frequency of the screen cuts has exceeded the limit or time!",
  CM_DailyPointsRule: "Daily sign in can gain {0} points",
  CM_Delete: "Delete",
  CM_Department: "Department",
  CM_DepartmentFillUp: "The  department is busy!",
  CM_Detail: "In detail",
  CM_Empty: "Empty",
  CM_EmptyPassword: "Password cannot be empty!",
  CM_EndTime: "End time",
  CM_EnterNewPassword: "Enter a new password",
  CM_EnterOriginalPassword: "Enter a password",
  CM_EnterQues: "Questions",
  CM_EverythingIsShown: "Everything is shown",
  CM_PullUpLoadMore: "Pull up load more",
  CM_Exam_CutChance:
    "With he number of screen cuts has been exhausted,you'll be forced to hand in the paper if you cut screen!",
  CM_Exchange: "Exchange",
  CM_Exist: "An item have not been answered!",
  CM_Expired: "Expired",
  CM_FaceToFaceName: "Off-line training name",
  CM_Fail: "Operation failed",
  CM_FailDelete: "Delete failed!",
  CM_FailUpload: "Upload failed!",
  CM_FailureEnroll: "Registration failed!",
  CM_FillUp: "The course is busy!",
  CM_FinInscriptions: "Registration ends!",
  CM_Finish: "Complete",
  CM_Finished: "Completed",
  CM_GetScore: "Score",
  CM_GetSuccess: "Receive success!",
  CM_HadNotSubmit: "Not submitted",
  CM_HadSubmit: "Submitted",
  CM_HasSignedUp: "You have already entered!",
  CM_Header: "Profile phote",
  CM_Hour: "hours",
  CM_Index: "Home",
  CM_IngenKlassificering: "There are omissive items need to be completed!",
  CM_InitiationTask: "You can not cancel after 'start'",
  CM_IsSubmitted: "In the submission",
  CM_Join: "Join",
  CM_LB_AttachmentFile: "Attachment",
  CM_LB_Credit: "Credit",
  CM_LB_Description: "Describe",
  CM_LB_Name: "Name",
  CM_LB_Progress: "Progress",
  CM_LB_Prompt: "Prompt",
  CM_LB_Reviews: "Comment",
  CM_LB_Save: "Preservation",
  CM_LB_Status_Null: "No",
  CM_LB_SubmitTime: "Submitting time",
  CM_MyStudy: "My study",
  CM_Level: "Grade",
  CM_Live: "Live",
  CM_Medal: "Medal",
  CM_Minute: "minutes",
  CM_MobilePhone: "Invalid phone number !",
  CM_ModifyPassword: "Change the password",
  CM_MostComments: "Most commentted",
  CM_Name: "Name",
  CM_NameSorting: "Sort by name",
  CM_Newest: "Newest",
  CM_NoAnswer: "No answer",
  CM_NoJoin: "wait to enter",
  CM_NoPass: "Fail",
  该考试设置了仅看错题答对的题目将不在此显示:"The test is set up to see only the wrong questions",
  CM_NoRight:
    "You do not have the right of control of department registeration",
  CM_NoTakePartIn: "Not involved",
  CM_NotCompleted: "Unfinished",
  CM_NotLearning: "wait to study",
  CM_OK: "Confirm",
  CM_Open: "open",
  CM_Pass: "Passed",
  CM_PassStatus: "Passing state",
  CM_Period: "Period",
  CM_Piece: "of it",
  CM_PleaseEnterContent: "Input......",
  CM_Points: "Points",
  CM_PopularityRanking: "Ranking of popularity",
  CM_PraiseMost: "most liked",
  CM_Public: "Open",
  CM_QA: "Q&A",
  CM_QAAnswer: "Answer",
  CM_QRCode: "QR code",
  CM_Refuse: "Refuse",
  CM_RepeatRegistration: "You can not repeat to register!",
  CM_Reply: "Reply",
  CM_Required: "Required",
  CM_Rule_BestTopic: "Best Topic",
  CM_Rule_CancelBest: "Cancel best",
  CM_Rule_CancelTop: "Cancel top",
  CM_Save: "Save",
  CM_SearchCurriculumData: "Search for Course & Data",
  CM_Second: "seconds",
  CM_Select: "Please select",
  CM_SelectAll: "Check all",
  CM_ServiceExperience: "Experience of service",
  CM_SignFail: "Sign in failed",
  CM_SignIn: "Login",
  CM_SignOutFail: "Sign out failed",
  CM_SignOutSuccess: "Sign out success",
  CM_SignRules: "Sign rules",
  CM_SignSuccess: "Sign in success",
  CM_Signup_NotAllow: "The department you belonged to refuse receiving users",
  CM_Skip: "Skip",
  CM_SorryCommentsTooFast: "Sorry, the frequency of your comments is too fast.",
  CM_SortSort: "Ranking",
  CM_StartedYet: "Expect",
  CM_StartTime: "Start time",
  CM_Status: "State",
  CM_StudyMode_Map: "Path graph",
  CM_Submit: "Submit",
  CM_SubmitFail: "Submit failed",
  CM_SubmitTime: "Submit time",
  CM_Success: "Operation succeed!",
  CM_SuccessfullyDelete: "Delete succeed!",
  CM_Survey: "Questionnaire",
  CM_TakePartIn: "Done",
  CM_Task: "Task",
  CM_ThumbUpSuccess: "Like successfully",
  CM_TimeDue: "You can not cancel now",
  CM_TimeIsNew: "Latest",
  CM_Tip: "Prompt",
  CM_UnExpired: "Not yet due",
  CM_Unfinished: "Incomplete",
  CM_Unlimited: "Unlimited",
  CM_UnSelectAll: "Deselect all",
  CM_UploadAttachment: "Please submit it after uploading attachment",
  CM_UserName: "Name",
  CM_UserPassWord: "Password",
  CM_ViewResult: "Check results",
  CM_WorkExperience: "Working experience",
  DR_Error_CourseIsEnd: "The course is over",
  DR_Error_CourseIsNotBegin: "Expect",
  DR_Error_CourseIsNotCanStudy: "Unaccessible now",
  DR_Error_CourseIsPrecondition: "Pre condition",
  DR_Error_CourseNeedSignUp: "Haven't registered",
  DR_Error_CourseNotExists: "No information of courses",
  DR_Error_CourseStudyPermissionError:
    "Sorry, you have no right to learn the course",
  DR_Error_CTIsConNotStudy: "Off-line training without authorization",
  DR_Error_DocumentCannotView: "Unable to view the document in the format",
  DR_Error_EndTimeFormatError: "The format of ending time is wrong",
  DR_Error_JsonFormatError: "The string format is wrong",
  DR_Error_SearchParamConvertError:
    "Search parameter entity conversion error is wrong",
  DR_Error_SearchParamIsEmpty: "Search parameter entity cannot be empty",
  DR_Error_StartTimeFormatError: "The format of start time is wrong",
  KM_HaveRead: "Have read",
  KM_NotFinished: "Unfinished",
  KM_Unread: "Will be read",
  Lab_ChooseLanguage: "Please choose the language:",
  Lab_CourseDetail_Collect: "Favorites",
  Lab_CourseDetail_CourseDetail: "Details",
  Lab_Doc_JoinEvaluate: "Participate in evaluation",
  Lab_Evaluate_M_BaseInfo: "Basic information of training",
  Lab_Evaluate_T_CanMulti: "More than one answer is right",
  Lab_Evaluate_T_Course: "Training course:",
  Lab_Evaluate_T_FeedbackInfo: "Training feedback",
  Lab_Evaluate_T_Teacher: "Lecturer:",
  Lab_Evaluate_T_TrainingAddress: "Training place:",
  Lab_Evaluate_T_TrainingDate: "Training time:",
  Lab_Evaluate_T_WebCastTime: "Time of live broadcast",
  Lab_Exam_UserScore: "{0} Points",
  Lab_ExamDetail_ReExamNum: "Times of resit",
  Lab_ExamDetail_ViewResult: "View answer",
  Lab_Group_AllTopic: "All topics",
  Lab_Group_ExitGroup: "Exit",
  Lab_Group_GroupLeader: "Group leader",
  Lab_Group_HotGroup: "Popuar group",
  Lab_Group_MyGroup: "My group",
  Lab_Group_NewestTopic: "New topic",
  Lab_Group_NotOpenToNonMembers:
    "Privacy (the topic of this group is open to the group members)",
  Lab_Group_TopicList: "Topic list",
  Lab_Homework_InputContent: "Please input the job content...",
  Lab_Homework_Submit: "Submit",
  Lab_Knowledge_HotSearch: "Hot search",
  Lab_KnowledgeArticleDetail_Publish: "Publish",
  Lab_Lesson_AllNumber: "Total number",
  Lab_Lesson_CorrectNumber: "Correct number",
  Lab_Lesson_CorrectRate: "Correct rate",
  Lab_Lesson_OpenState: "Open state",
  Lab_Lesson_ParticipationPercentage: "Participation percentage",
  Lab_Lesson_QuestionFull: "Please fill out the questionnaire",
  Lab_Lesson_RightKey: "Right answer",
  Lab_Lesson_SubmitNumber: "Submission number",
  Lab_Lesson_YourAnswer: "Your answer",
  Lab_Lesson_YouRight: "You got it!",
  Lab_Lesson_YouWrong: "Sorry, the answer is wrong",
  Lab_LessonDetail_T_LiveDetail: "Live details",
  Lab_LessonDetail_T_SignIn: "Sign in",
  Lab_LessonDetail_T_SignOut: "Sign out",
  Lab_LessonInteract_Closing: "Condition",
  Lab_LessonInteract_Photograph: "Photograph",
  Lab_LessonInteract_SignIn: "Sign in",
  Lab_LessonInteract_SignOut: "Sign out",
  Lab_Mall_Conversion: "Exchange",
  Lab_MyExam_ExaminationHistory: "History Exam",
  Lab_MyExam_InExamination: "Formal exam",
  Lab_MyFavorite_CancelFavorite: "Remove",
  Lab_MyFavorite_FavoriteDate: "Collection time",
  Lab_MyHonnor_Draw: "Receive",
  Lab_MyIntegra_IntegralGrade: "Scoring grade",
  Lab_MyIntegra_T_GradeIntegral: "Scoring grade",
  Lab_MyIntegra_TotalScore: "Total score",
  Lab_MyIntegral_IntegralRule: "Scoring rule",
  Lab_MyIntegral_Onetime: "Disposable",
  Lab_MyInteract_CommentDesc: "Evaluated",
  Lab_MyInteract_DiscussionGroup: "Group",
  Lab_MyInteract_MyComment: "My comments",
  Lab_MyInteract_MyReplay: "My reply",
  Lab_MyPlan_GetCredit: "Get credit",
  Lab_MySignUp_T_Ranking: "Ranking",
  Lab_News_Cancel: "Cancel",
  Lab_News_News: "News",
  Lab_Signup_Msg_Audit: "Your application is being checked",
  Lab_Signup_Msg_Success: "Registration succeed",
  Lab_SignUp_Status: "Status",
  Lab_Signup_T_CancelRegistration: "Cancel registration",
  Lab_Signup_T_GoSign: "Enter",
  Lab_Subject_SynthesizeOrder: "Comprehensive sorting",
  Lab_Teacher_Fen: "Scores",
  LB_AboutInstructor: "introduction",
  LB_AccessibleIntegral: "Integrable integral",
  LB_Account_BindAnotherWeiXin: "Current bound account: {0}",
  LB_Account_EnterLogo: "Please enter enterprise labeling",
  LB_Account_InputAccount: "Please enter the username",
  LB_Account_InputPassword: "Please input a password",
  LB_AllPhotos: "All photos",
  LB_AlreadyThumbsUp: "You've already praised it!",
  LB_Appraiser: "The evaluator",
  LB_AReward: "Reward",
  LB_Arrange: "Arrangement",
  LB_AttachmentView: "Please check the attachment for the computer terminal",
  LB_AudioFrequency: "Audio",
  LB_AuditResult: "Review the results",
  LB_AutoBack: "Return automatically after {0} seconds",
  LB_Blend: "mix",
  LB_CancelPointPraiseSuccess: "Cancel point praise success",
  LB_Cert_BeginLearn: "Start learning",
  LB_Cert_Certificate: "Certificate",
  LB_Cert_Finished: "Has been completed",
  LB_CertifiedCertificate: "Can be a certificate",
  LB_Charge: "charges",
  LB_ChoseCourseCatalog: "Select the course catalog you are interested in",
  LB_ClearRearchRecords: "Clear search record",
  LB_ClickUploadPhotos: "Click upload",
  LB_Coach_EnterName: "Please enter a name",
  LB_Coach_SelectCategory: "Select category",
  LB_Comment_ReplyTo: "Reply to {0}:",
  LB_Comment_SaySomething: "Say something ...",
  LB_CommentDeleted: "The comment has been &#100;eleted",
  LB_ConfirmationReward: "Confirm reward",
  LB_Course_Cate: "Catalogue",
  LB_Course_CourseDoc: "Learning materials",
  LB_Course_FinalStudy: "Final study",
  LB_Course_Goal: "Course objective",
  LB_Course_StartStudy: "Study Now",
  LB_Course_ToStudy: "To learning",
  LB_Course_StudyProgress: "Studing progress",
  LB_Course_TrainTarget: "Training object",
  LB_CourseDetailPlay: "Do not support, please go to PC to learn this course",
  LB_Credit_CreditsOfTheMonth: "Credits of the Month",
  LB_Credit_CreditThisYear: "Credit this year",
  LB_Credit_TotalCredit: "Total credit",
  LB_CT_AvailableCertificates:
    "Certificates available for completing the course",
  LB_CT_AvailableMedals: "Medals for completing courses",
  LB_CT_CourseOutline: "Course outline",
  LB_CT_FaceType: "Off-line training type",
  LB_CT_MemberNo: "No information",
  LB_CT_NotSign: "Not yet enrolled",
  LB_CurriculumFormat: "Curriculum format",
  LB_DepartmentRankings: "Department rankings",
  LB_DetermineAssignment: "Do you really want to submit the test page?",
  LB_Doc_AddDescription: "Add description",
  LB_Doc_Comments: "Comment",
  LB_Doc_Confirm_Delete: "delete confirm",
  LB_Doc_Failure: "Operation failed",
  LB_Doc_FavoriteSuccess: "Collecttion successed",
  LB_Doc_Mobile_Load: "Loading...",
  LB_Doc_Mobile_Screening: "Screen",
  LB_Doc_Source: "Sources",
  LB_Doc_UploadTime: "Upload time",
  LB_DocumentConversionWait: "Documents is converting,please wait...",
  LB_EnterReward: "Fill in the number you want to reward",
  LB_Error_ExamHasEnd: "exam has ended",
  LB_Error_ExamHasIng: "exam in progress",
  LB_Error_ExamHasTime: " starting exam later",
  LB_Error_SurveyNotBegin: "This questionnaire has not started",
  LB_Error_SurveyNotExist: "This questionnaire does not exist",
  LB_Error_SurveyNotView: "This questionnaire is not allowed to read",
  LB_Error_SurveyOverdue: "This questionnaire is overdue",
  LB_Error_SurveyPrepositionNot:
    "This precondition of this questionnaire is not completed",
  LB_Evaluate_Score: "Points",
  LB_Exam_AllQuestion: "All",
  LB_Exam_AnswerLessBanpaper:
    "{0}minutes at least before you hand in the paper!",
  LB_Exam_AreYouHand:
    "There are {0} questions haven't been answerd,confirm submit?",
  LB_Exam_BlankFilling: "Fill in the blanks",
  LB_Exam_Casus: "Case",
  LB_Exam_Cert: "Certificate",
  LB_Exam_CertStatus: "Certificate status",
  LB_Exam_Countdown: "Count down",
  LB_Exam_CutScreenNum: "Times of cut-screen",
  LB_Exam_Detail: "Details",
  LB_Exam_EnterCounts: "Reference times",
  LB_Exam_ErrorQuestion: "Wrong answer",
  LB_Exam_EvaluationRules: "Grading rules",
  LB_Exam_ExamNum: "Times of examination",
  LB_Exam_HandPaperReturnExam: "Submit succeed!It'll return to My Exam",
  LB_Exam_Judgement: "Judge",
  LB_Exam_LateControl: "Late control",
  LB_Exam_MultiSelect: "multiple-choice(More than one answer is correct)",
  LB_Exam_NestQuestion: "Next question",
  LB_Exam_PassCondition: "Passing conditions",
  LB_Exam_QuestionAnalysis: "The question analysis",
  LB_Exam_Radio: "Multiple-choice(Only one answer is correct)",
  LB_Exam_RightQuestion: "The right answer",
  LB_Exam_Secrecy: "Secrecy",
  LB_Exam_StartExam: "Start",
  LB_Exam_SubPaperControl: "Submit control",
  LB_Exam_TestTime: "Exam duration",
  LB_Exam_ViewAnswerPaper: "Check paper",
  LB_Exam_WellQuestion: "Right Answer",
  LB_Examdhm: "There are {0} days {1} hours {2} minutes before the exam",
  LB_ExamRules: "Exam rules",
  LB_Favorite_Cancel: "Remove",
  LB_Favorite_Score: "Grade",
  LB_Favorite_SomePraise: "Likes",
  LB_Gene_Choose: "Select at least one",
  LB_Group_AddTag: "Add tag",
  LB_Group_AddTitle: "Add title",
  LB_Group_CancelCollection: "Make sure to remove?",
  LB_Group_CanNotReward: "You can't tip to your essay",
  LB_Group_CoverPhoto: "Cover",
  LB_Group_EditorialGroup: "Edit the group",
  LB_Group_Group: "Group",
  LB_Group_GroupHead: "Group avater",
  LB_Group_GroupMembers: "Group members",
  LB_Group_GroupType: "Group type",
  LB_Group_Hot: "Hot",
  LB_Group_ICreated: "I created",
  LB_Group_IJoined: "I entered",
  LB_Group_JoinGroup: "Join the group",
  LB_Group_NeedAudit: "You can enter them after audit",
  LB_Group_NewGroup: "New group",
  LB_Group_NotNeedAudit: "You can enter them without an audit",
  LB_Group_Publish: "Publish",
  LB_Group_RewardArticle: "Reward the essay?",
  LB_Group_RewardSuccess: "Reward successfully!",
  LB_Group_SearchGroup: "Search Group",
  LB_Group_SharingCode: "Sharing QR",
  LB_HighestScore: "Highest score: {0} points",
  LB_HistoricalRecord: "Records",
  LB_Picture: "Picture",
  LB_Index_CT: "My off-line training",
  LB_Integral_Deduction: "Deduct",
  LB_Integral_Get: "Get",
  LB_Integral_MonthIntegral: "month's integral",
  LB_Integral_YearIntegra: "year's integral",
  LB_ISDeleteComment: "Make sure to delete?",
  LB_IsExpired: "Expired or not",
  LB_LearningRankings: "Learning rankings",
  LB_lecture_Star: "Star",
  LB_lesson_TeachingTime: "Teaching time",
  LB_Level_DeductingIntegral: "Deduct points",
  LB_Level_GetIntegral: "Get points",
  LB_Level_Score: "You need {0} scores to upgrade",
  LB_Live_EnterLive: "Enter the live",
  LB_Live_LiveType: "Live broadcast type",
  LB_Live_WaitLive: "Please wait for the speaker to start the live broadcast",
  LB_Live_WhetherParticipate: "Participate in or not",
  LB_Mall_AllGoods: "All goods",
  LB_Mall_Exchange: "exchange",
  LB_Mall_ExchangeRecord: "Exchange records",
  Exchange_rules: "Exchange rules",
  LB_Mall_GoToExchange: "Exchange",
  LB_Mall_ICanExchange: "I can exchange",
  LB_Mall_Integral: "Integral is insufficient",
  LB_Mall_People: "people have ehanged",
  LB_Mall_StillLeft: "Left",
  LB_Mall_Tips: "Exchange and not cancel?",
  LB_Manager_WhetherPass: "Pass or not",
  LB_Map_DurationDays: "Duration",
  LB_Map_SubDetails: "Complete all",
  LB_Marked: "Marked",
  LB_MedalAwarded: "Medal awarded",
  LB_Message_SelectDeleteCollection:
    "Please choose a collection to &#100;elete",
  LB_Message_SelectDeleteComment: "Please &#115;elect comments to &#100;elete",
  LB_Message_SelectDeleteReply: "Please choose the reply to &#100;elete",
  LB_Message_YouWantDeleteCollection:
    "Are you sure you want to &#100;elete the currently &#115;elected collection",
  LB_Message_YouWantDeleteComment:
    "Are you sure you want to &#100;elete the currently &#115;elected comments",
  LB_Message_YouWantDeleteReply:
    "Are you sure you want to &#100;elete the currently &#115;elected reply",
  LB_Minutes: "{0} minutes",
  LB_Msg_ConfirmDeleteSelect: "Are you sure to delete the selected message",
  LB_Msg_DeleteWithNoItem: "Select the message you want to delete",
  LB_Msg_Total: "{0} messages",
  LB_My_Content: "My content",
  XB_MyManage: "My management",
  LB_My_Ge: " ",
  LB_My_GetCredit: "Get {0} credit",
  LB_My_HelpCenter: "Help Center",
  LB_My_History: "History",
  LB_My_LearningAchievements: "Learning achievements",
  LB_My_Mei: " ",
  LB_My_Teaching: "Teaching",
  LB_My_Tiao: " ",
  LB_My_UpdateLog: "Log",
  LB_MyLearningState: "My learning state",
  LB_MyPhoto: "My Photo",
  LB_MyRanking: "My ranking is in  {0} place.",
  LB_Mysinup_Project: "Project",
  LB_News_NewsState: "News state",
  LB_News_NumberOfVisitors: "The number of visitors",
  LB_NoIntroduction: "No introduction",
  LB_NotCharge: "Free",
  LB_NotInRankings: "I haven't made the list yet.",
  LB_NotListData: "No Data",
  LB_NotUploadClickUpload:
    "You don't have profile photos, {0}tap the botton to upload!",
  LB_NPAwardedCourse: "{0} people have awarded this course",
  LB_NumberWinners: "The number of lucky dog",
  LB_NumPersonLearning: "{0} people study",
  LB_Old_CT: "Teaching History",
  LB_Period_TotalStudyTime: "Current year study time",
  LB_PersonScoring: "{0} people marked",
  LB_Photo: "picture",
  LB_PhotoNum: "The number of picture",
  LB_PleaseQuestions: "Please write the questions",
  LB_Pro_ChangeLanguage: "Language switch",
  LB_Pro_DailyAttendance: "Daily Attendance",
  LB_Pro_Di: "No.{0}",
  LB_Pro_Integral: "Integral",
  LB_Pro_ObtainPoints: "Obtain {0} Points",
  LB_Pro_RankedAgainst: "Your rank defeat {0} competitors",
  LB_QA_Accepted: "Accepted",
  LB_QA_AddAnswer: "Reply more",
  LB_QA_AddQues: "Ask more",
  LB_QA_AdoptCurrent: "Determine to adopt the current answer",
  LB_QA_BeenDeleted: "This question has been deleted",
  LB_QA_ChooseRewardValue: "Choose reward",
  LB_QA_DescribeDoubt: "Please describe your question (not less than 8 words)",
  LB_QA_DesIsNoe: "Description should be no less than 8 words",
  LB_QA_HotQuestion: "Hottest question",
  LB_QA_IncreaseReward:
    "Increase the reward, it's more likely to attract the master to answer",
  LB_QA_IsResolved: "Solved",
  LB_QA_IsWonderful: "Adopted",
  LB_QA_IToAnswer: "I'd like to reply",
  LB_QA_Mine: "My",
  LB_QA_NewQuestion: "Latest question",
  LB_QA_NoReplyMsg: "No reply . Elite helps to solve it",
  LB_QA_PleaseEnter: "Please write",
  LB_QA_Praised: "Praised",
  LB_QA_ReplyQuestion: "Reply to more question",
  LB_QA_Status: "Solution state",
  LB_QA_UnResolved: "Unsolved",
  LB_QA_YourCreditBalance: "Your credit balance",
  LB_Ranking_FinishCourse: "{0}courses",
  LB_Ranking_FinishPeriod: "{0}Period",
  LB_RecentSearch: "Recently Search",
  LB_ReferenceTimes: "Reference times: {0} times",
  LB_RefreshSuccessful: "Refresh successful",
  LB_Rule_Action: "Action",
  LB_Rule_Cycle: "Period",
  LB_Search_Clear: "Are you sure  to clear searching records?",
  LB_SelectEndTime: "Choose end time",
  LB_SelectStartTime: "Choose start time",
  LB_SendOut: "Send",
  LB_Set_EmptyPassword: "The original password cannot be empty",
  LB_Set_OriginalPassword:
    "The length of the original password must be 6-20 digits",
  LB_Settings_Birthday: "Birthday",
  LB_Settings_Cellphone: "Mobile phone",
  LB_Settings_ConfirmPasswordNotNull: "Confirm that the password is not empty",
  LB_Settings_Email: "Mailbox",
  LB_Settings_Msg_EmailFormatError: "Incorrect mail format",
  LB_Settings_Msg_TelephoneFormatError: "Incorrect TEL number",
  LB_Settings_NewPasswordNotNull: "New password cannot be empty",
  LB_Settings_NotTheSamePassword: "Two times passwords are not consistent",
  LB_Settings_OriginalPasswordIncorrect: "The original password is wrong",
  LB_Settings_PasswordLengthError: "Password length must be 6-20 digits",
  LB_Settings_Sex: "Gender",
  LB_Settings_Telephone: "Tel",
  LB_Shield: "Shield",
  LB_Shielding: "Shielded",
  LB_Signup_RegistrationType: "Registration type",
  LB_StartMakeUp: "Start resit",
  LB_Study_ComeOn:
    "Hi,You still have {0} learning items remaining to be completed. Come on.",
  LB_Subject_DiscussionCircle: "Discussion circle",
  LB_SubmitEvalute: "Submit evaluation paper",
  LB_SubmitStatus: "Submittion state",
  LB_Survey_DispatchMethod: "Way of distribution",
  LB_Survey_Participate: "Participate in questionnaire",
  LB_Survey_SubmitSuccessAndJumping:
    "Questionnaire submitted successfully, returning ...",
  LB_Tagged: "Mark",
  LB_AnswerSheet: "Answer",
  LB_Teacher_CreateTime: "Create time",
  LB_Teacher_HavaPerusal: "Marked",
  LB_Teacher_InteriorTeacher: "Internal lecturer",
  LB_Teacher_NoAnyServiceExperience: "No service experience",
  LB_Teacher_NoAnyWorkExperience: "No work experience",
  LB_Teacher_SubSuccess: "Submitted successully",
  LB_Teacher_TeacherType: "Lecture type",
  LB_Teacher_WithoutTeacher: "External lecturer",
  LB_TotalData: "A total of {0} data",
  LB_Track_LastPageAlert: "honye,it comes to the end",
  LB_TrainingDetails: "Training details",
  LB_TrainingProgram: "Training outline",
  LB_UnlimitedTimes: "Unlimited times",
  LB_UploadingAttachments: "Upload files",
  LB_WinningResult: "Result",
  M_Ranking_Integral: "Get Integral",
  MN_Course_MHome: "Course",
  MN_DocumentCenter_MHone: "Data",
  MN_Exercise_MHone: "Exercise",
  MN_Group_MHone: "Group",
  MN_IntegralMall_MHome: "Mall",
  MN_KC_MHome: "KC",
  online_exam: "online exam",
  MN_LearningPaths_MHome: "LearningPaths map",
  MN_MyCourse_MHome: "My course",
  MN_MyWebCast_OldLive: "Past",
  MN_MyWebCast_PCStudyCenter: "My live",
  MN_News_LeftMenu: "News",
  MN_OfflineTraining_MHome: "Training face to face",
  MN_QA_MHome: "Q&A",
  MN_Subject_MHome: "Targeted training",
  MN_Survey_MHome: "Questionnaire",
  MN_TrainingEnrollment_MHome: "Training Enrollment",
  MN_TrainingProject_MHome: "Project",
  MN_TrainingProject_Detail: "project details",
  MN_Exam_Detail: "exam details",
  Pub_AlreadyReceive: "Received",
  Pub_AlreadyRegistered: "Registered",
  Pub_Article: "Article",
  Pub_Authorize: "Authorization",
  Pub_Btn_AlreadySign: "Signed in",
  Pub_Btn_Edit: "Edit",
  Pub_Btn_Submit: "Submit",
  Pub_Close: "Close",
  Pub_Completed: "Completed",
  Pub_CT_AssembleForTraining: "assemble for training",
  Pub_CT_SingleDoor: "Single door",
  Pub_Data: "Knowledge",
  Pub_Day: "Day",
  Pub_Document: "File",
  Pub_Elective: "Elective",
  Pub_Essence: "Best",
  Pub_Exam_NoExam: "Examination is not allowed for the time being",
  Pub_Exam_SubmitExamIsTimeOver:
    "The examination time is over and the examination paper is being submitted.",
  Pub_Female: "Female",
  Pub_Hour: "Hour",
  Pub_Join: "Participated",
  Pub_Lab_Administrators: "Administrator",
  Pub_Lab_AnonymousSubmission: "Anonymous submission",
  Pub_Lab_Collect: "Collection",
  Pub_Lab_Intro: "introduction",
  Pub_Lab_NoIntro: "No Introduction",
  Pub_Lab_CreateTime: "Create time",
  Pub_Lab_DirectoryName: "Directory name",
  Pub_Lab_DocType: "Data type",
  Pub_Lab_ExamSource: "Exam source",
  Pub_Lab_ExamStatus: "Exam state",
  Pub_Lab_ExamTime: "Exam time",
  Pub_Lab_FinishStatus: "Completion state",
  Pub_Lab_Group: "Group",
  Pub_Lab_HomeworkSource: "Come from",
  Pub_Lab_IsExpired: "Expired or not?",
  Pub_Lab_MarkingStatus: "Review state",
  Pub_Lab_NoData: "No data",
  Pub_Lab_NoRank: "No ranking",
  Pub_Lab_PendingAudit: "Pending review",
  Pub_Lab_PleaseEnterSearchKeywords: "Please enter search keywords",
  Pub_Lab_Post: "Post",
  Pub_Lab_ProjectStatus: "Project status",
  Pub_Lab_ProjectTime: "Project time",
  Pub_Lab_PublishedIn: "Published in",
  Pub_Lab_ReplyMe: "Reply to me",
  Pub_Lab_ReplySuccess: "Reply successfully",
  Pub_Lab_StudyType: "Learning type",
  Pub_Lab_SurveyStatus: "Survey state",
  Pub_Lab_ThroughCondition: "Pass requirements",
  Pub_Lab_Top: "Set to top",
  Pub_Lab_Topic: "Topic",
  Pub_Lab_UploadTime: "Upload time",
  Pub_Lab_UserAudit: "Personnel review",
  Pub_Lab_UserName: "Name",
  Pub_LP: "Map",
  Pub_Man: "Male",
  Pub_Minutes: "mark",
  Pub_Msg_ActivityNoOpen: "Current activity is off",
  Pub_Msg_DJoinG: "Are you sure to join in the group?",
  Pub_Msg_DOutG: "Are you sure to exit ?",
  Pub_Msg_DUAuditF: "Are you sure the user does not pass the audit?",
  Pub_Msg_DUAuditS: "Are you sure the user audit succeed?",
  Pub_Msg_NoGetHonor: "You have not received this medal",
  Pub_Msg_NotGet: "Not get",
  Pub_Msg_NoRepeatSubmit: "Non-repeatable submission",
  Pub_Msg_SaveFail: "Save unsuccessfully!",
  Pub_Msg_SaveSuccess: "Save successfully!",
  Pub_Msg_SubmitFail: "Submit unsuccessfully!",
  Pub_Msg_SuccessComment: "Comment successfully",
  Pub_Msg_TitleNotNull: "Title cannot be empty",
  Pub_Normal: "Normal",
  Pub_Notexpired: "Not expired",
  Pub_NotObtainedCertificate: "You have not received this certificate",
  Pub_Notpassed: "Not passed",
  Pub_NotRegistered: "Not registered",
  Pub_NotSubmitted: "Unsubmitted",
  Pub_Open: "Public",
  Pub_Ord_AgreeNum: "Likes",
  Pub_Ord_Answer: "Answers",
  Pub_Ord_CompleteProgress: "Progress",
  Pub_Ord_Progress: "progress",
  Pub_Ord_Recommend: "Recommend",
  Pub_Ord_ReplayTime: "Reply time",
  Pub_Ord_ViewNum: "Views",
  Pub_Passed: "Passed",
  Pub_PathMap: "Path map",
  Pub_Rebut: "Dismissed",
  Pub_RecommendedCourse: "Recommended course",
  Pub_Required: "Required",
  Pub_Submitted: "Submitted",
  Pub_Tab_AlreadyRegistered: "Registered",
  Pub_Tab_Assessment: "Assessment",
  Pub_Tab_Attended: "Attended",
  Pub_Tab_Checkpoint: "Level",
  Pub_Tab_CommentNum: "Comment",
  Pub_Tab_Credit: "Credit",
  Pub_Tab_CT: "Face",
  Pub_Tab_Detail: "Details",
  Pub_Tab_Enrollment: "Enrollment",
  Pub_Tab_EveryDay: "Every day",
  Pub_Tab_GetIntegralByday: "Continuous attendance {0} days bonus {1} points",
  Pub_Tab_GoSign: "Go sign",
  Pub_Tab_Knowledge: "Knowledge",
  Pub_Tab_LastStudy: "Last study",
  Pub_Tab_NotStart: "Not started",
  Pub_Tab_Progress: "Progress",
  Pub_Tab_ReplyTo: "Reply to",
  Pub_Tab_ReRegistration: "Re-registration",
  Pub_Tab_Score: "Score",
  Pub_Tab_Time: "Time",
  Pub_Tab_TrainingTime: "Training time",
  Pub_TaskOrCT: "Project /Off-line training",
  Pub_Uncommitted: "Not participated",
  Pub_Uncompleted: "Unfinished",
  Pub_Video: "Video",
  Pub_WithoutApproval: "Not marked",
  Sys_Exam: "Exam",
  Sys_Homework: "Work",
  Sys_WatchLive: "Watch {0} Minute",
  Sys_WebCast: "Live",
  AI_Practice: "AI Practice",
  AI_Practice_tips: "please go to mobile end to participate in ai training",
  XB_AccordingAcademicScore: "According to the academic score",
  XB_Add_Content: "Additional content",
  XB_AddElectives: "Add electives",
  XB_AddingTaskFailed: "Adding task failed",
  XB_Advance: "Recommend to you",
  XB_AreYouFinished: "Are you sure it's finished?",
  XB_AreYouSureCancelTopping: "Are you sure you want to cancel topping?",
  XB_AreYouSureDeleteComment: "Are you sure to &#100;elete this comment?",
  XB_AreYouSureDeleteGroup:
    "Are you sure you want to &#100;elete the current discussion group?",
  XB_AreYouSureDeleteReply: "Are you sure you want to &#100;elete this reply?",
  XB_AreYouSureDeleteTopic:
    "Are you sure you want to &#100;elete the current topic?",
  XB_AreYouSureEssenceStatus:
    "Are you sure you want to cancel the essence status?",
  XB_AreYouSureQuitGroup:
    "Are you sure you want to quit the current discussion group?",
  XB_AreYouSureSetElite: "Are you sure to set it to elite status?",
  XB_AreYouSureTopStatus: "Are you sure you want to set it to top status?",
  XB_AuthorizationTime: "Authorization time",
  XB_Back_Home: "Back to home page",
  XB_Basic_Info:
    "The CC content has included the basic information of the training, which is the additional content",
  XB_BeenExitedGroup: "The current group has been exited.",
  XB_BeingPublished: "Being published ...",
  XB_BindAccount: "Bind account number",
  XB_BindingFailed: "Binding failed",
  XB_BindingSucceeded: "Binding succeeded",
  XB_Bonus_Points: "Bonus points cannot be empty",
  XB_ByNumberCompletedResources: "By number of completed resources",
  XB_Cc: "Cc",
  XB_Check_Staff: "Check in staff",
  XB_Choose_Content: "Please choose CC content",
  XB_Choose_Method: "Please choose CC method",
  XB_ChooseOperation: "Choose operation",
  XB_Class_Address: "Class address",
  XB_CompanyRanking: "Company ranking",
  XB_Completed_Course: "Congratulations on completing the course",
  XB_Completed_First: "You must finish the previous chapter first!",
  XB_ConfigurationExam: "Configuration exam",
  XB_ConfirmPassword: "Confirm password",
  XB_CongratulationsGetCert: "Congratulations on getting the [{0}] certificate",
  XB_CongratulationsGetMedal: "Congratulations on getting the [{0}] medal",
  XB_Copy_Send_Info: "Cc information",
  XB_Copy_Send_Method: "Cc method",
  XB_CorporateIdentity: "corporate identity",
  XB_CourseInformation: "Details",
  XB_CustomsClearance: "Customs clearance",
  XB_DesignatedLearners: "Designated learners",
  XB_DetailNumber: "Details",
  XB_Distinguish: "Long press to identify QR code",
  XB_Duration: "Long",
  XB_DynamicQRCode: "Dynamic QR code",
  XB_End_Time: "The end time has passed",
  XB_EndTimeCannotEarlierThanStartTime:
    "End time cannot be earlier than start time",
  XB_Enter_Class_Address: "Enter class address",
  XB_Enter_Face_Name: "Enter face to face name",
  XB_EnterpriseIdNotEmpty: "Domain cannot be blank",
  XB_EssenceBeenCanceled: "Essence status has been cancelled",
  XB_Exam_AnswerCannotBeViewed: "The answer sheet cannot be viewed",
  XB_Exam_AreYouSureSaveAnswerInfo:
    "Are you sure you want to save the current answer information?",
  XB_Exam_SaveAnswerInfo: "The answer sheet information has been saved for you",
  XB_ExaminationOpeningConditions: "Examination opening conditions",
  XB_ExitFailed: "Exit failed",
  XB_Face_To_Face: "Face to face basic information",
  XB_Famous_Teachers: "Recommended by famous teachers",
  XB_FirstCompleteFrontResource: "Please complete the pre-resource first",
  XB_Five_Star_Class: "Five star class",
  XB_FixedQRCode: "Fixed QR code",
  XB_Get_Integral: "Enter to get integral. The default value is 0",
  XB_GetFollowingHonors:
    "The following honors can be obtained through this learning map:",
  XB_Give_Reward: "Please give me a reward",
  XB_GiveUpContinueAnswerQuestions:
    "Are you sure you want to give up and continue to answer questions?",
  XB_Good_Review: "give it a good review",
  XB_Growth_Sharing: "Growth sharing",
  XB_Heat: "Heat",
  XB_Hello: "hello",
  XB_Time: "Hours",
  XB_HowManyDaysExpire: "{0} days to expire",
  XB_HowManyPeople: "There are currently {0} people on the list",
  XB_Insufficient_Points: "Insufficient points",
  XB_Interaction_Page: "Returning to interactive home page",
  XB_InteractiveConfiguration: "Interactive configuration",
  XB_Invitation: "invitation",
  XB_Invited: "Invite you to study",
  XB_Is_Required: "Is required",
  XB_ItsCourse: "Its course",
  XB_Join_Study: "Join study",
  XB_Join_Study_First: "Please join the study first",
  XB_Join_Successfully: "Join successfully",
  XB_Joined: "Joined",
  XB_JoinFailed: "Join failed",
  XB_Learn: "Learn",
  XB_Learn_Together: "learn together",
  XB_Lecturer: "Lecturer",
  XB_Lecturer_Details: "Lecturer details",
  XB_LoginInProgress: "Login in progress ...",
  XB_Mail: "Mail",
  XB_Mall_BeenRedeemed: "It has been redeemed",
  XB_Mall_ExchangeFailed: "Exchange failed!",
  XB_Manuscripts: "Manuscripts",
  XB_MobileCannotDownload:
    "The mobile terminal cannot download, please go to the PC terminal",
  XB_MobileCTNotEdit:
    "On the Mobile side, training does not currently support editing",
  XB_MobileOnlyForDisplay: "PC configuration, Mobile only for display",
  XB_Modify_Job: "Modify job",
  XB_Month: "Month",
  XB_MyMotto: "My motto",
  XB_Nail: "Nail",
  XB_NewFailed: "The new failed",
  XB_NewPassword: "New password",
  XB_NoExamYet: "There is no exam yet",
  XB_NoHistoricalRecord: "There is no historical record",
  XB_Not_Converted: "The current chapter has not been converted",
  XB_Not_Drag: "You can not drag to a non viewed progress",
  XB_NotCollected: "You haven't collected it yet",
  XB_NotFilledIn: "Not filled in",
  XB_NoUpdateLog: "There is no &#117;pdate log",
  XB_NumberLevels: "levels",
  XB_NumberStages: "stages",
  XB_Obtain_certificates: "You have obtained the following certificates",
  XB_Obtain_medals: "You have obtained the following medals",
  XB_Open_To_Use: "Open to use",
  XB_OptionalCourses: "Optional courses",
  XB_OriginalPassword: "Original password",
  XB_OtherConfigurations: "Other configurations",
  XB_Parameter_Error: "Parameter error",
  XB_PassExamGetFollowCert: "Pass the exam to get the following certificates:",
  XB_PassExamGetFollowMedals: "Pass the exam to get the following medals:",
  XB_Pattern: "pattern",
  XB_Person_Learning: "Person learning",
  XB_PleaseChooseEndTime: "Please choose the end time",
  XB_PleaseChooseLecturer: "Please choose the lecturer",
  XB_PleaseChooseStartTime: "Please choose the start time",
  XB_PleaseEnterClassAddress: "Please enter the class address",
  XB_PleaseEnterLessonHour: "Please enter the lesson hour",
  XB_Popular_Recommendation: "Popular recommendation",
  XB_Press_Send:
    "Press and hold the picture above to save or send it to a friend",
  XB_PublicationFailed: "The publication failed",
  XB_PublishedSuccessfully: "Published successfully",
  XB_RankingList: "Ranking list",
  XB_ReadIt: "Read it",
  XB_RegistrationControl: "Registration control",
  XB_RequiresCompletionFrontLevel:
    "Unlocking requires completing the front level",
  XB_Return:
    "You have successfully submitted your job and are returning to the previous page",
  XB_Returning: "Returning",
  XB_Reward_Failed: "Reward failed",
  XB_Saving: "Saving. ...",
  XB_Select_Send_Method: "Choose cc method",
  XB_SelectCompletionCriteria: "Choose the completion criteria",
  XB_SelectedCourses: "&#115;elected courses",
  XB_SelectQRCodeType: "Choose the QR code type",
  XB_Send_Not_Empty: "CC person cannot be empty",
  XB_Send_Success: "Sent successfully",
  XB_SendTopic: "Send a topic",
  XB_SetupSucceeded: "Setup succeeded",
  XB_Share: "Share",
  XB_Share_Course: "Share with you",
  XB_SignUpSuccessfully: "Sign up successfully",
  XB_Singing_Off: "Person signing off",
  XB_SorryNoMatchingAccount:
    "Sorry, no matching account number can be found in the learning platform. Please contact the administrator!",
  XB_StageNotOpen: "Stage is not open",
  XB_Star_Score: "Touch the star score",
  XB_Start_Quick: "Start learning quickly",
  XB_StartLessonReminder: "Start the lesson reminder",
  XB_Strive: "strive",
  XB_Submit_Success: "Submitted successfully, returning",
  XB_Success_Back:
    "You have successfully submitted your assessment and are returning to the previous page",
  XB_SummaryFirst: "Please fill in the summary first",
  XB_SupportedFileFormats: "Supported file formats:",
  XB_Teaching_Hours: "Teaching hours",
  XB_TemporarilyUnableToLearn: "Temporarily unable to learn",
  XB_Test_Scores: "Test scores",
  XB_TheVersionNumber: "The version number",
  XB_Think_Good: "I think this course is good",
  XB_Today: "Today",
  XB_ToppingBeenCancelled: "The topping status has been cancelled",
  XB_UnbindingFailed: "Unbinding failed",
  XB_UnbindingSucceeded: "Unbinding succeeded",
  XB_UploadingDocument: "Uploading document...",
  XB_UserNameNotEmpty: "User name cannot be empty",
  XB_VerifyYourIdentity: "Please wait while you verify your identity ...",
  XB_WatchedIt: "I watched it",
  XB_WeChart: "WeChart",
  XB_Week: "Week",
  XB_YouNotBound:
    "You haven't bound your learning platform account yet, so hurry to bind and start WeChat learning!",
  LB_Account_BindingAccount: "BindingAccount",
  XB_Course_CompletionConditions: "Course completion conditions",
  XB_Finish: "Finish",
  XB_Test: "Test",
  XB_Text: "Document",
  XB_DocumentText: "Document Text",
  XB_Course_ChapterSituation: "Course chapter situation",
  XB_Studying: "Studying",
  XB_FinishedLearning: "Finish",
  XB_DidNotLearn: "Did not learn",
  XB_StartDate: "Start time",
  XB_EndDate: "End date",
  XB_ViewRules: "View rules",
  XB_NoMoreExamRecordsSo:
    "There are no more exam records, so take the exam as soon as possible",
  XB_ExamDate: "Exam date",
  XB_Min: "min",
  XB_JustMomentPendingApproval: "Waiting for approval.",
  XB_CongratulationsPassedExam: "Congratulations, passed this exam!",
  XB_UnfortunatelyNoPassExam: "Unfortunately, fail the exam.",
  XB_ExaminationHistory: "Examination history",
  XB_Mark: "Mark",
  XB_NoMoreExamRecords: "There are no more test records",
  XB_ThisExamRules: "Rules of this exam",
  XB_ExamNeedingAttention: "Matters needing attention in examination",
  XB_PassExamToGet: "Pass the exam to get",
  XB_Waiting: "Waiting",
  XB_CurrentIntegralAre: "Your current integral is",
  XB_AdminSideslipEdit: "Administrator sideslip can be edited",
  XB_MyRanking: "My ranking",
  XB_DefeatOpponent: "Defeat the opponent",
  XB_SpecifyLearningContent: "Specify the learning content",
  XB_CertificatesCanObtainedTask: "Certificates that can be obtained",
  XB_MedalCanObtainedTask: "Medal that can be obtained",
  XB_UnderWay: "Underway",
  XB_OpeningHours: "Opening hours",
  XB_ExerciseDuration: "Exercise duration",
  XB_RemainingDuration: "Remaining duration",
  XB_CorrectNumber: "Correct number",
  XB_TotalNumberQuestions: "Total number",
  XB_StudentMedal: "Student medal",
  XB_CertificateHonor: "Certificate of honor",
  XB_FacialIdentification: "Facial identification",
  XB_HasSampled: "Has sampled",
  XB_Resampling: "Resampling",
  XB_Unthread: "Unthread",
  XB_WantTakeSamples: "Take samples",
  XB_SignInWithPrizes: "Sign in with prizes",
  XB_SignInTodayGet: "Sign in today and get",
  XB_QuickRegistration: "Quick registration",
  XB_AnalysisQuestions: "Analysis of test questions",
  XB_GradeDifficulty: "grade of difficulty",
  XB_EndExercise: "End the exercise",
  XB_Collected: "Collected",
  XB_CorrectRateTime: "Correct rate this time",
  XB_MyWrongQuestion: "My wrong question",
  XB_NumberExercises: "Number of exercises",
  XB_WrongExercises: "Wrong exercises",
  XB_BackExercises: "Back to exercises",
  XB_ExerciseTimeExpired:
    "The current exercise participation time has expired, so you cannot participate again",
  XB_CurrentErrorEmpty: "The current error title is empty",
  XB_SorryNoExercises: "Sorry, you have no exercises to participate in",
  XB_SorryNoExam: "Sorry, you have no exams to take",
  XB_InitExercisesFailed: "Failed to initialize practice record",
  XB_ContinueLastAnswer: "Continue the last answer?",
  XB_MockExamFailed: "You failed the mock exam at present, and scored",
  XB_MockExamPassed: "Your current mock exam has passed, and scored",
  XB_MyQuestionBank: "My question bank",
  XB_ExamRecord: "Exam record",
  XB_MockExam: "Mock exam",
  XB_SwitchQuestionBank: "Switch question bank",
  XB_SequentialPractice: "Sequential practice",
  XB_SequenceExerciseTopic: "Practice the questions in the specified order",
  XB_RandomPractice: "Random practice",
  XB_RandomExerciseTopic: "Exercise topic of random rules",
  XB_DifficultExercises: "Difficult exercises",
  XB_CarefullyScreenDifficultProblems:
    "Carefully screen all the difficult problems in the question bank",
  XB_MyCollection: "My collection",
  XB_CollectExercises: "Collect key topics in simulation exercises",
  XB_WrongQuestionSet: "Wrong question set",
  XB_SummarizeExerciseMistakes: "Summarize all the mistakes in the exercise",
  XB_SwitchExercise: "Switch exercise",
  XB_SelectValidExercise: "Please select a valid exercise",
  XB_NoExercises: "There are no exercises at present",
  XB_NoLeaderboard: "There is no leaderboard yet",
  XB_NoExamRecord: "There is no exam record at present",
  XB_NoExamFrequency: "You have run out of exams",
  XB_CurrentNotRanking:
    "The current ranking is not on the list, continue to cheer ~",
  XB_NoScoreRanking:
    "You haven't had an exam yet, and you don't have a score ranking ~",
  XB_CongratulationsRight: "Congratulations, you got it right",
  XB_GotOnlyPartRight: "You got only part of it right",
  XB_UnfortunatelyAnswerWrong: "Unfortunately, your answer is wrong",
  XB_Cockpit: "Cockpit",
  XB_Files: "Files",
  XB_ViewPlayback: "View playback",
  XB_ServiceAgreement: "Service agreement",
  XB_PrivacyPolicy: "Privacy policy",
  XB_Interactions: "{0} interactions",
  XB_LogOut: "Log out",
  login: {
    account: 'Account',
    account_p: 'Please enter your mobile phone number/email/account',
    account_p2: 'Please enter your account',
    password: 'Password',
    password_p: 'Please enter password',
    captcha: 'Captcha',
    请输入验证码: 'Please enter captcha',
    register: 'Register',
    forgot: 'Forgot',
    scan_code_login: 'Scan code login',
    account_login: 'Account login',
    use_palform_scan: 'Please use {0} to scan the QR code to log in',
    wx: 'WeChat',
    ding: 'DingDing',
    your_q_r_code_is_invalid: 'Your QR code is invalid',
    please_click_the_refresh_button_below: 'Please click the refresh button below',
    refresh: 'refresh',
    failed_to_create_q_r_code: 'Failed to create QR code',
    login_failed: 'Login failed',
    //
    mobile_or_email: 'Mobile or email',
    mobile_or_email_p: 'Please enter mobile or email',
    mobile_or_email_err: 'Mobile number or email address is incorrect',
    code: 'Code',
    code_p: 'Please enter code',
    new_password: 'New password',
    new_password_p: 'Please enter new password',
    confirm_password: 'Confirm password',
    confirm_password_p: 'Please enter confirm password',
    not_match: 'The two passwords do not match',
    to_login: 'To login',
    successfully_retrieved_the_password: 'Successfully retrieved the password!',
    success_tip: 'Your password has been reset, please log in to the platform to try',
    get_code: 'Get code',
    failed_get_code: 'Failed to get code',
    //
    account_registration: 'Account registration',
    enterprise_registration: 'Enterprise registration',
    enterprise: 'Enterprise',
    enterprise_p: 'Please enter enterprise',
    enterprise_tip: "If you can't find your organization in the organization list, contact the person in charge of the organization | Start on the platform",
    account_regist: 'Please enter at least 3 characters',
    name: 'Name',
    name_p: 'Please enter your name',
    address: 'Adress',
    area: 'Area',
    area_p: 'Please select a area',
    mobile: 'Mobile',
    请输入手机号: 'Please enter your mobile',
    mobile_err: 'Mobile number is incorrect',
    email: 'Email',
    email_p: 'Please enter your email',
    register_btn: 'Register',
    register_success_title: 'Congratulations, your registration is successful!',
    register_success_tip: 'Your account has been successfully registered, go and log in to the platform to try it out.',
    enterprise_name: 'Enterprise name',
    enterprise_name_p: 'Please enter enterprise name',
    agree: 'Agree',
    submit: 'Register',
    termsOfService: '《Traingo terms of Service》',
    legalNotice: '《Legal Notice and Privacy Policy》',
    readAndTick: 'Please read and tick 《Traingo terms of Service》 《Legal Notice and Privacy Policy》',
    //
    emial_format_err: 'E-mail format is incorrect',
    tel_format_err: 'incorrect landline format',
    password_len: 'The password cannot be less than 8 characters',
    password_strong: 'The password cannot be less than 8 characters and needs to contain any three of uppercase letters, lowercase letters, numbers and symbols',
    please_enter_chinese: 'Please enter Chinese',
    please_enter_the_value: 'Please enter the number',
    //
    personal_information: 'Personal information',
    birthday: 'Birthday',
    birthday_p: 'Please select birthday',
    landline: 'Landline',
    landline_p: 'Please enter landline',
    save_submit: 'Save and submit',
    old_password: 'Old password',
    ok_to_modify: 'OK to modify',
    avatar: 'Avatar',
    点击复制:'Click to copy',
    upload_avatar: 'Upload avatar',
    logout_tip: 'Are you sure you want to log out?',
    账号密码登录:'Username and Password Login',
    手机号登录:"Phone Number Login"
  },
  next: 'Next',
  pre: 'Pre',
  and: 'and',
  mine: {
    month: 'M',
    lv: 'LV',
    chatGPT: 'ChatGPT',
    live_course: 'Live course',
    my_lecture: 'My lecture',
    my_certificate: 'My certificate',
    exam_review: 'Exam review',
    review_completed: 'review completed',
    my_order: 'My order',
    activation_key_management: 'Activation key',
    unit_management: 'Unit manage',
    my_credits: 'My credits',
    my_school_hours: 'My school hours',
    my_integral: 'My integral',
    my_likes: 'My likes',
    current_version: 'Current version',
    already_the_latest_version: 'Already the latest version!',
    clear_the_cache: 'Are you sure to clear the cache ?',
    clear_immediately: 'Clear',
    clear_success: 'clear success',
    change_password_tip: 'first time login | Login password expired',
    change_password_tip_title: 'To improve account security, {0} must modify the original password.',
    camera_permissions: 'Please go to settings to enable camera permissions',
    successfully_modified: 'Successfully modified!',
    learning_center: 'Learning center',
    my_face: 'My face',
    my_exam: 'My exam',
    my_activity: 'My activity',
    learning_path: 'Learning path',
    interaction_center: 'Interaction center',
    homework_approval: 'Homework',
    help_manage: 'Help manage',
    self_help: 'Self help',
    lecturer_apply: 'Lecturer apply',
    demand_report: 'Demand',
    educational: 'Educational',
    complete_online_course: 'Complete online course',
    month_learn_hours: 'Online courses + exam time + knowledge in training',
    month_study_time: 'Month study time',
    month_study_time_rank: 'Month study time rank',
    no_task: 'There are currently no tasks assigned. Let\'s learn other tasks～',
    set_off: 'Set off',
    sure_delete_comment: 'Are you sure you want to delete this comment',
    sure_delete_reply: 'Are you sure you want to delete this reply',
    list_date: 'List date',
    list_together: 'List together',
    people: 'people',
    company_rank: 'Company rank',
    department_rank: 'Department rank',
    see_more_list: 'See more list',
    message_center: 'Message center',
    total_of: 'A total of | {0}',
    delete_msg_tip: 'Sure you want to delete currently selected news?',
    please_choose_to_delete_message_content: 'Please choose to delete message content',
    cumulative_signin: 'Cumulative sign-in for {0} day this month',
    sign_in: 'Sign in',
    current_level: 'Current level',
    tier_points: 'Tier Points',
    level_comparison_table: 'Level comparison table',
    level_top: 'Your level has reached the top, is it the legendary god? accept worship',
    get_another_nth_points_to_upgrade_to: 'Get another {0} points to upgrade to Lv{1}',
    score_rule_tip: 'Performing the following actions will earn points, and there is a limit to the number of rewards you can receive in a single move cycle.',
    learning_archive: 'learning archive',
  },
  Version: 'Version',
  daily_exam: 'Daily',
  cm_subject: 'Subject',
  cm_package: 'Package',
  training_package_details: 'training package details',
  training_package_purchase: 'training package purchase',
  cm_newstaff: 'Newstaff',
  newstaff_details: 'Newstaff details',
  learning_details: 'learning details',
  news_list: 'news list',
  news_details: 'news details',
  face_to_face_details: 'face-to-face details',
  questionnaire_details: 'questionnaire details',
  questionnaire_survey: 'questionnaire survey',
  task_supervision: 'Task supervision',
  cm_activity: 'Activity',
  demand_collection: 'Demand collection',
  cm_project: 'Project',
  face_lecturer: 'Face lecturer',
  lecturer_face: 'Lecturer face',
  attendance_face: 'Attendance face',
  exam_approval: 'Exam approval',
  cm_bbs: 'Community',
  circle_members: 'circle members',
  make_up_exam: 'Make-up exam',
  chat: {
    mode: 'Text | Drawing',
    input_p: 'You can directly enter your question here~',
    load_text: 'getting messages | Message fetched successfully',
    answer_tip: 'Hello, it is a pleasure to serve you! If you encounter any problems, you can directly enter them here~',
    send_tip: 'Please enter valid content | Responding',
  },
  face_state: 'Face State',
  from_start: 'From start',
  from_end: 'From end',
  on_line: 'online',
  offline: 'offline',
  issued_online: 'Issued online',
  Valid_until: 'Valid until',
  permanent: 'permanent',
  Review_times: 'Review times',
  source_task: 'Source task',
  CM_Personal: 'Personal',
  CM_Unit: 'Unit',
  CM_OrderId: 'OrderId',
  XB_AreYouSureDeleteOrder: "Are you sure to delete the order?",
  validity_period: 'Validity period',
  currency_symbol: '$',
  order: {
    month: 'month',
    order_type: 'Order type',
    order_state: 'Order state',
    order_stats_option: 'Paid | Unpaid',
    order_id: 'Order id',
    wechat_id: 'Wechat id',
    pay_time: 'Pay time',
    total: 'Total',
    View_activation_code: 'View activation code',
    Activation_code_list: 'Activation code list',
    activation_code: 'Activation code',
    user: 'User',
    usage_time: 'Usage time',
    price: 'Price',
    activation_option: 'Activated/Inactivated',
    use_state_option: 'Used | Unused',
    purchase_time: 'Purchase time',
    purchase_quantity: 'Purchase quantity',
    purchasing_price: 'Purchasing price',
    please_enter_package_name: 'Please enter a package name',
    table: {
      serial: 'Serial',
      package_name: 'Package name',
      orderId: 'OrderId',
      buy_account_num: 'Buy account num',
      time: 'Time',
      remaining_code: 'Remaining code',
      operate: 'Operate',
      提交时间: 'Submission time',
      activation_code: 'Activation code',
      name: 'Name',
      account: 'Account',
      tel: 'Tel',
      use_time: 'Use Time',
    },
  },
  CM_Year: 'year',
  CM_quantity: 'Quantity',
  enterprise: {
    personnel_infor: 'Personnel infor | Personnel infor list',
    personnel_learning: 'Personnel learning | Personnel learning List',
    table: {
      avatar: 'Avatar',
      account: 'Account | Please input account',
      real_name: 'Real name | Please type in your name',
      unit: 'Unit',
      state: 'State | Please select a status',
      package: 'Package | Please enter a training package name',
      study_time: 'Study time',
      whether_to_pass: 'Whether to pass',
      learning_progress: 'Learning progress',
      study_completed: 'Study completed | Please select a completion status',
      create_time: 'Create time',
    },
    info_state: 'Normal | Disabled',
    pass_state: 'Passed | No pass',
    learn_state: 'Completed | In progress | Undone',
    complete_time: 'Complete time',
    recent_study: 'Recent study',
    have_completed_all: 'Congratulations, you have completed all the tasks!',
    have_not_started_learn: "You haven't started learning yet!",
    tip: 'You have completed | tasks, and there are | learning tasks left!',
  },
  credits: {
    total_credits: 'Total credits',
    year_credits: 'Year credits',
    month_credits: 'Month credits',
    adjust_credits: 'Adjust credits',
    remark: 'Remark',
    Remark_text: 'Completion of 【{0}】 bonus credits',
    online_courses: 'Online courses',
    training_task: 'Training task',
    year: 'Annual',
    tips: 'Used to record you complete study of reward points',
    all_record: 'All credit record',
  },
  mytime: {
    total_hours: 'Total hours',
    year_hours: 'Year hours',
    month_hours: 'Month hours',
    Remark_text: 'Completion of {0} bonus credit hours',
    tips: 'Used to record your reward school complete study',
    all_record: 'All school record',
    table: {
      name_of_resource: 'Name of resource',
      resource_type: 'Resource type',
      time: 'Time',
      descr: 'Descr',
    },
  },
  integral: {
    level_integral: 'Level integral',
    available_integral: 'Available integral',
    year_integral: 'Year integral',
    month_integral: 'Month integral',
    get_time: 'Get time',
    remark: 'Remark',
    answer_practice: 'Answer practice',
    table: {
      action: 'Action',
      cycle: 'Cycle',
      integral: 'Integral',
      daily_limit: 'Daily limit',
      cycle_state: 'Disposable | Not limited',
      descr: 'descr',
      bonus_points: 'Bonus points',
    },
    tips: 'Used to record you log on, share, comment and learning resources have rewarded by platform activity',
    all_record: 'Records of all their points',
    cycle_tip: 'one-time: this action needs to be done only once, reward 1 times.',
  },
  like: {
    news_like: 'News like',
    knowledge_like: 'Knowledge like',
    like_time: 'Like time',
    news_category: 'Category',
    knowledge_category: 'Category',
  },
  select_date: 'Select date',
  study: {
    title: 'My learning tasks',
    to_be_completed: 'To be completed',
    month: 'Month',
    learn_in: 'Learn in {0} {1}',
    title_2: 'My studies',
    title_tip: "Here's what you're learning and to-dos",
    learning_tasks: 'Learning tasks',
    no_task: "No task yet, let's study independently",
    filter_type: 'Filter type',
    overdue_learn: 'Overdue learn',
    days_remaining: '{0} days remaining',
  },
  home: {
    search_p: 'Please enter key content',
    more_features: 'More features',
    referenced: ' people referenced',
    exam_num: 'Exam num',
    go: 'Go',
    d: 'd',
    face: 'Face',
    person_study: 'learned',
    waiting: 'Waiting',
    rank_format: {
      h: `h`,
      m: `m`,
      s: `s`,
    },
    learned_last_time: 'learned last time',
    continue_studying: 'Continue studying',
    init_data: 'Initializing, please wait.',
    cannot_study: "can't study",
    resources_tip: 'Please complete the preceding resources first',
    not_yet_started: 'not yet started',
    not_yet_expired: 'activity has expired',
    recommend: 'Recommend',
    hot_list: 'Hot',
    top: 'Top',
    infor: 'Infor',
    infor_tip: 'A panoramic view of world events and industry information',
    konwledge_tip: 'The treasure house of corporate knowledge allows you to understand the business one step faster',
    no_hot_content: 'No hot content',
  },
  no_lecturer: 'No lecturer',
  search: {
    no_search_record: 'No search record yet',
    enter_keywrod: 'Please enter a keyword!',
    history_search: 'History search',
    delete_all: 'Delete all',
    hot: 'Hot',
    total: 'Total | results found',
    noData: 'Sorry, no related content was found.',
  },
  resource: {
    resource_center: 'Resource Center',
    resource_center_tip: 'Selected courses · Lecturer recommendation',
    featured_courses: 'Featured Courses',
    all_courses: 'All courses',
    view_more_course: 'View more course',
    no_course: 'No course content yet',
    knowledge_selection: 'Knowledge selection',
    all_knowledge: 'All knowledge',
    no_knowledge: 'No knowledge content yet',
    industry_big_coffee: 'Industry big coffee',
    no_industry: 'No big names in the industry',
  },
  category: 'Category',
  course: {
    learning_times: 'Learning times',
    total_progress: 'Progress',
    switch_video_play_mode: 'Switch video playback mode',
    audio_play: 'Audio play',
    video_play: 'Video play',
    now_playing: '（Playing）',
    speed: 'Speed',
    no_course_permission: 'You do not have learning permission for this course',
    confirm_learning: 'Please confirm if you are still learning?',
    please_enter_course: 'Please enter course name',
    format: 'Format',
    to_learn: 'To learn',
    lessons_learned: 'Lessons learned',
    you_have_completed_this_section_of_study: 'Congratulation，you have completed this section of study',
    classes: '{0} classes',
    people_score: '{0} people score',
    course_intro: 'Course intro',
    course_catalog: 'Course catalog',
    course_grade: 'Course grade',
    course_comment: 'Course comment',
    section: 'Section {0}',
    course_not_exist: 'Sorry, the course you are accessing does not exist',
    this_chapter_is_currently_playing: 'This chapter is currently playing',
    the_current_chapter_is_not_converted: 'The current chapter is not converted',
    thank_you_for_your_rating: 'Thank you for your rating',
    study_stop_continue: 'The current study has been suspended, whether to continue to study',
    face_tip: 'Please put your | face | into the recognition frame for recognition',
    face_click_tip: 'The face has been recognized, please click OK',
    evaluate: 'Evaluate',
    homework: 'Homework',
    go: 'Go | Go | Go',
    need_tip: 'Course chapters are not yet finished, no exams are available for now',
    looking_forward_to_your_positive_comments: 'Looking forward to your positive comments',
    your_encouragement_can_make_us_do_better: 'Your encouragement can make us do better',
    online_course: 'Course',
  },
  project: {
    project_classify: 'Project classify',
    implement_classify: 'Implement classify',
    left_over: 'Left over {0}',
    assign_personnel: 'Assign personnel',
    overdue_not_completed: 'Overdue not completed',
    time_left: 'Time left',
    project_progress: '',
    total_of: 'Toatl {0}',
    supervisor: 'Supervisor',
    progress_needs: 'Project progress needs to reach {0}%',
    exams_required_pass: '{0} exams required to pass',
    cannot_study: 'Cannot study without starting the project',
    project_overdue: 'Project overdue',
    passing_the_exam: 'Can only be passed after passing the exam',
    stages: 'Stages {0}',
    details: 'Details',
    catalog: 'Catalog',
    comments: 'Comments',
    require: 'Require',
    not_exist: 'Sorry，The training project you accessed does not exist。',
    business_account: 'Business account',
    other_system_business_accounts: 'Other system business accounts',
    default_Pwd: 'Default password',
    other_system_address: 'Other system access address',
    empty_tip: 'The current account currently has no business account. Please contact the platform administrator',
    noLearning_tip: 'Only after passing the study can one obtain a business account and password',
  },
  not_unlocked: 'Not unlocked',
  map: {
    month: 'Month',
    go_pc: 'Please go to PC to learn!',
    click_unlock: 'Please click on the level on the map to unlock',
    learning_cycle: 'Learning cycle',
    learn_in_sequence: 'Learn in sequence',
    learn_order: 'Open | Unopened',
    clearance_conditions: 'Clearance conditions',
    learning_progress_required: 'Learning progress requirement {0}%',
    pass_exam_num: '{0} passing exams required',
    mentor: 'Mentor',
    Learning_maps_overview: 'Learning Maps overview',
    number_exams_passed: 'Number of exams passed',
    resource_details: 'Resource details',
    no_exist: 'Sorry, your access to learning map does not exist.',
    current_level: 'Current level',
    level_progress: 'Level Progress',
    overall_progress: 'Progress',
    nth_people_study: '{0} people studying',
    learning_map_desc: 'Learning map desc',
    my_rank_tip: 'A total of | students to study together, my ranking is | , beat',
    my_rank_tip_2: 'of the students ~',
    level_state: 'Level state',
    Learning_period: 'Learning period',
    Learning_progress_tip: 'Total learning progress of all resources/Total number of resources',
    pass_condition: 'Pass condition',
    pass_nth_exam: 'passed {0} exams',
    level_desc: 'Level desc',
    help_and_plan: 'Help and plan',
    help_and_evaluation: 'Help and evaluation',
    help_to_summarize: 'Help to summarize',
    table: {
      serial: 'Serial',
      type: 'Type',
      name: 'Name',
      status: 'Status',
    },
    close_modal_tip: 'Sure study close current window?',
    evaluation_mentor: 'Evaluation mentor',
    i_already_know__confirm_done: 'I already know, confirm done',
    tutor_no_plan: 'The tutor has not yet made a plan',
    submit_summary: 'Submit summary',
    submit_summary_p: 'When submitting long content, it is recommended to edit it with other software and paste it here',
    continue_to_submit: 'Continue to submit',
    please_fill_out_summary_content: 'Please fill out summary content',
    "学习地图开启了关卡间按顺序学习，完成上一关卡后才能解锁当前关卡": "The learning map has enabled sequential learning between levels, and the current level can only be unlocked after completing the previous level",
    "学习地图开启了关卡间按顺序学习，完成上一关卡且达到上一关的学习期限后才能解锁当前关卡": "The learning map has enabled sequential learning between levels. The current level can only be unlocked after completing the previous level and reaching the learning deadline of the previous level",
    "当前尚未达到学习地图开始学习时间": "The start time for learning the map has not yet been reached",
  },
  newstaff: {
    training_state: 'Training State',
    progress: '',
    congratulations: 'Congratulations on completing your task!',
    progress_needs: 'Training progress needs to reach {0}%',
    training_overdue: 'Training overdue',
    no_exist: 'Sorry, you visit newstaff training does not exist.',
  },
  homework: {
    work_requirements: 'Work require',
    step: 'step {0}',
    optional: '(Optional) Please fill in the content of the operation steps. When submitting long content, it is recommended to use the client software to edit it first, and then paste it here.',
    add_steps: 'Add steps',
    attachment: '{0} Attachments',
    review_time: 'Review time',
    reviewer: 'Reviewer',
    comments: 'Comments',
    save_current_step: 'Please save the current editing step first',
    keep_one_step: 'keep at least one step',
    upload_attachment_count: 'Up to {0} attachments can be uploaded',
    save_tip: 'Please fill in the content or upload the attachment',
    approval: 'Approval',
    table: {
      name: 'Name',
      score: 'Score',
      wait: 'Unapproved/Total Approved',
      state: 'State',
      operate: 'Operate',
      提交时间: 'Submission time',
    },
    homework_content: 'Homework content',
    score: 'Score',
    please_fill_in_the_score: 'Please fill in the score',
    full_score: 'Full score',
    all_approvals_are_complete: 'All approvals are complete!',
    successful_approval: 'Successful approval',
    sure_delete_step: 'Are you sure you want to delete this step?',
    valuation: 'Valuation',
    StudentList: 'Student List',
    NotEvaluated: 'Not evaluated',
    ViewEvaluation: 'View Evaluation',
    AverageScore: 'average score',
    Department: 'Department',
    NotStarted: 'Homework not started',
    HasEnd: "Homework has end",
  },
  cm_upload: 'Upload',
  upload: {
    uploading: 'Uploading',
    success: 'Success',
    max_size_tip: 'The file size cannot exceed {0}',
    max_count_tip: 'Up to {0} files can be uploaded',
    failed: 'Failed',
    accept: 'Support pictures, documents, audio, video, up to {0} attachments can be uploaded, and a single file should not exceed {1}',
    upload_img: 'Upload img',
    upload_video: 'Upload video',
    file_siez_cannot_be: 'File size cannot be 0',
    please_upload_a_picture_or_video: 'Please upload a picture or video',
    upload_files: 'Upload files',
    please_upload_the_file: 'Please upload the file',
    supported_file_types: 'Supported file types',
    drop_tip: 'Click or directly drag and drop files here to upload.',
    upload_error: 'Upload error',
    the_file_format_is_not_supported: 'The file format is not supported',
  },
  quesition: {
    is_submit_tip: 'You have participated in this event!',
    my_result: 'My result',
    view_statistics: 'View statistics',
    please_enter: 'Please enter',
    view_option_desc: 'View option desc',
    write_evaluate: 'Write a evaluate',
    please_enter_evaluate: 'Please enter evaluate',
    emial_error: 'E-mail format is incorrect',
    mobile_error: 'Mobile number is incorrect',
    please_select: 'Please select',
    please_rate: 'Please rate',
    select_at_least_options: 'Select at least {0} options',
    select_up_to_options: 'Select up to {0} options',
    cannot_be_less: 'Cannot be less than {0}',
    enter_at_least: 'Enter at least {0} characters',
    cannot_be_greater: 'Cannot be greater than {0}',
    enter_up_to: 'Enter up to {0} characters',
    content_is_incomplete: 'The content is incomplete!',
    quesition_option_required: 'Question {0} Select at least one label!',
    activity_has_expried: 'The activity has expired, you are not participating!',
    please_select_answer: 'Please select answer',
    please_enter_content: 'Please enter content',
    nth_people_have_answered: '{0} people have answered',
  },
  go_back: 'Go back',
  second_automatic_jump: ' second automatic jump',
  practice: {
    answer_practice: 'Answer practice',
    answer_record: 'Rrecord | Answer record',
    mode: {
      daily: 'Daily',
      daily_tip: 'Sun is new every day',
      interest: 'Interest',
      interest_tip: 'Interest is the best teacher',
      wrong: 'Wrong',
      wrong_tip: 'Learn from mistakes',
      simulation: 'Simulation',
      simulation_tip: 'Master certain test-taking skills and accumulate test experience',
      self: 'Self',
      self_tip: 'Find out the loopholes in the review and avoid making mistakes again and again',
    },
    vibration: 'Vibration',
    vibration_tip: 'Vibration control during answering questions',
    iphone_tip: 'For Iphones, please turn on the ring/silent mode to vibrate.',
    sound_effects: 'Sound effects',
    sound_effects_tip: 'During the answering process, there are sound effects for correct and incorrect answers',
    integral: 'Integral',
    question: '',
    limit_reached: 'Limit reached',
    error_times: 'Error times',
    view_tips: 'View tips',
    number_of_times: '',
    correct_quesition: 'Correct quesition',
    wrong_quesition: 'Wrong quesition',
    use_time: 'Use time',
    integral_2: 'Integral',
    one_more_group: 'One more group',
    no_quesition: 'No practice questions yet',
    exit_tip: 'Are you sure you want to exit? \n Answer history will not be saved after exiting',
    remove_tip: 'Are you sure you want to remove the wrong question? \nAfter removal, it will not appear in the wrong question practice',
    remove_success: 'Remove success',
    no_interest: 'It is not in the opening period for answering exercises\nPlease wait',
    select_tags: 'Select tags of interest',
    can_select_multiple: 'You can select multiple',
    go_to_answer: 'Go to answer',
    wrong_question_set: 'Wrong question set',
    view_and_practice: 'View wrong questions and practice',
    total_wrong_questions: 'Total wrong questions',
    view_wrong_question: 'View',
    wrong_question_practice: 'Practice',
    frequent_wrong_questions: 'Frequent wrong questions',
    simulation_exercise: 'Simulation exercise',
    standard_Practice_tests: 'Standard Practice Tests',
    total_questions: 'Questions',
    total_score: 'Toatl score',
    pass_line: 'Pass line',
    score: '',
    enter_practice: 'Enter practice',
    no_simulation: 'Open hours for non-simulation exercises\nStay tuned',
    quesition_num: '{0}',
    your_choice: 'Your choice',
    time_end_submit: 'Practice time has ended, papers are being submitted',
    sure_submit_paper: 'Are you sure you want to submit your test paper?',
    unanswered_to_submit: 'There are still {0} questions unanswered,\nAre you sure you want to submit the test paper?',
    return_page: 'You have submitted the test paper, will return to the previous page',
    self_practice: 'Self practice',
    quesition_bank: 'Total {0} questions in the question bank',
    answer_time: 'Answer time',
    paper_total_score: 'Toatl score',
    no_self: 'Non-independent practice open period \nStay tuned',
  },
  package: {
    purchase_status: 'Purchase status',
    personal_price: 'Personal price',
    enterprise_price: 'Enterprise price',
    purchased: 'Purchased',
    not_purchased: 'Not purchased',
    personal_purchase: 'Personal | Person purchase',
    enterprise_purchase: 'Enterprise | Enterprise purchase',
    minimum_account: 'Minimum {0} account',
    yuan: '',
    good_rating: 'Good rating',
    buyers: 'Buyers',
    free_purchase: 'Free purchase',
    active: 'Active',
    price: '${0}',
    less_account: 'Minimum {0} account',
    pay_tip: 'After the payment is successful, you will receive an activation code email, and you can also check the activation code through the unit management',
    to_pay: 'To pay',
    form: {
      activation_code: 'Ccode',
      activation_code_p: 'Please enter {0}-digit Code',
      captcha: 'Captcha',
      请输入验证码: 'Enter captcha',
      exchange: 'Exchange',
      activation_success: 'Activation successful',
    },
    purchase_success: 'Successful purchase',
    pay_success: 'Successful payment',
    min_buy: 'min buy',
    people_buy: '{0} people buy',
    good_rate: 'Good rate',
    after_buy_the_course: 'After buy the course',
    is_valid_until: 'is valid until',
    code_exchange: 'Exchange',
    details: 'Details',
    recommend: 'Recommend',
    not_exist: 'Sorry，The training package you accessed does not exist。',
    activation_code_activation: 'Activation code activation',
    commodity_order: 'Commodity order',
    payment_amount: 'Pay amount',
    WeChat_payment: 'WeChat pay',
    qr_expires_tip: 'There are | seconds left before the QR code expires, please refresh the page to get the QR code again after the expiration',
    open_phone_wechat_pay: 'Please open WeChat on your mobile phone and scan to complete the payment',
    qr_has_expired: 'The QR code has expired, please refresh the page to get the QR code again!',
    package_infor: 'Package infor',
    scan_code_pay: 'Scan code pay',
    table: {
      cover: 'Cover',
      name: 'Package name',
      quantity: 'Quantity',
      price: 'Price',
      validity_period: 'Validity period',
    },
    training_rating: 'Training rating',
    package_has_expired: 'The training package has expired',
  },
  cm_price: 'Price',
  news: {
    have_read: 'Have read | Have read',
    publish_time: 'Publish time',
    hot: 'Hot',
    new: 'New',
    disclaimer: 'Disclaimer',
    copyright_notice: 'Copyright Notice',
    finish_reading: 'Finish reading',
    no_lickes_allowed: 'No lickes allowed!',
    no_comments_allowed: 'No comments allowed',
    no_exist: 'Sorry, the news you visited does not exist.',
    news_center: 'News center',
  },
  cm_download: 'Download',
  cm_downloading: 'Downloading...',
  exam: {
    participation_status: 'Participation status',
    test_name: 'Test name',
    times: 'Exam num',
    unlimited: 'Unlimited',
    overdue_can_learn: 'Overdue can learn',
    pass_line: 'Pass line',
    make_up_exam: 'Make-up exam',
    enter_make_ip: 'Enter',
    not_yet_exam_time: 'Not start',
    total_score: 'Total score',
    total_score_tip: 'The total score of this exam is',
    cut_screen_tip: 'Cut screen not allowed',
    cut_screen_tip_2: 'The number of screen cuts in this test is {0} times。',
    rule_unlimited: 'Unlimited',
    cut_screen_tip_3: 'If the number of screen cuts is exceeded, the rule system will force the paper to be handed over.',
    exam_record_view: 'Exam record view',
    exam_record_view_tip: 'Exam papers can be viewed after submission.',
    exam_record_view_tip_2: 'Exam papers can be viewed after passing.',
    exam_record_view_tip_3: 'Exam papers can be viewed after the exam is over.',
    exam_record_view_tip_4: 'Viewing of test papers is not permitted.',
    mandatory_submission_rules: 'Mandatory submission rules',
    mandatory_submission_rules_tip: 'Enter the answering interface to start timing, countdown ends If you do not hand in the papers yourself, the system will force you to hand in the papers',
    mandatory_submission_rules_tip_2: 'Enter the answering interface to start timing, and exit the answering interface if the paper is not handed in. The test time will continue to count, and the time will end The system will force the submission',
    study_reward: 'Study reward',
    study_reward_tip: 'Pass this exam to get the certificate',
    last_count: 'Last count',
    cannot_exam: "Can't exam",
    exam_is_over: 'Exam is over',
    exam_result: 'exam results',
    exam_result_query: 'Exam result query',
    format: {
      d: 'd',
      h: 'h',
      m: 'm',
      s: 's',
      now: 'Now',
      m_ago: '{0} minutes ago',
      h_ago: '{0} hours ago',
      d_ago: '{0} days ago',
      month_ago: '{0} months ago',
      y_ago: '{0} years ago',
      hh: 'h',
      mm: 'm',
    },
    make_up_pass: 'Make-up PassEd',
    view_exam_sheet: 'Pass the exam to view the answer sheet | Only after the exam is over can the answer sheet be viewed',
    clear: 'Clear',
    exam_signature: 'Exam signature',
    exam_signature_tip: '"Signature Confirmation" is enabled for this exam, please use the mouse to sign in the signature box below (press and hold the mouse)',
    please_sign: 'Please sign',
    signed_successfully: 'Signed successfully',
    signature_failed: 'Signature failed',
    right_answer: 'Right answer',
    case_title: 'A total of {0} small questions, a total of {1} points',
    expand_case: 'Expand',
    view_pic: 'View Pic',
    view_video: 'View Video',
    view_audio: 'View audio',
    pre_quesition: 'Pre quesition',
    keyword: 'Keywords',
    score_keywords: 'Score keywords',
    single_choice: 'Single choice',
    multiple_choice: 'Multiple choice',
    save: 'Save',
    no_quesition: 'No test questions yet',
    doexam_cut_screen_tip: 'Please note that screen cutting is not allowed for this exam',
    doexam_cut_screen_tip_2: 'Note that there are {0} exam cutscenes left and the cutscene time ${1}',
    mandatory_submission: '',
    whether_save_paper: 'Whether to save the test paper',
    submit_paper_wait: 'Submitting papers, please wait',
    in_approval: 'Approval',
    my_answer: 'My answer',
    not_score: 'Not score',
    quesition_num: 'Quesition {0}',
    fill_in_blanks: 'Fill in blanks',
    not_answer: 'Not answer',
    answers: 'Answers',
    score_ratio: 'Score ratio',
    exam_info: 'Exam info',
    exam_name: 'Exam name',
    approval_count: 'Approval count',
    approval_progress: 'Progress',
    answer_info: 'Answer info',
    student_name: 'Student name',
    exam_time: 'Exam time',
    exam_score: 'Exam score',
    successful_approval: 'Successful approval',
    all_approved: 'All approved',
    to_score: 'To score',
    score: 'Score',
    nth_score: '{0} score',
    exam_not_started: 'Not started',
    note: 'Note',
    exam_description: 'Exam Description',
    no_description: 'No description',
    submit_time: 'Submit time',
    use_time: 'Use time',
    nth_s: '{0} S',
    exam_err_tip: 'Sorry, the exam you visited {0}',
    daily_err_tip: 'Sorry, the daily exam you visited {0}',
    total_duration: 'Total duration',
    auto_scoring_tip: 'If there are subjective questions, the system does not support automatic scoring',
    submit: 'Submit',
    unanswered_to_submit: 'There are still {0} questions unanswered, | Are you sure you want to submit the test paper?',
    exam_time_has_end: 'Exam time has ended and exam papers are being submitted. . .',
    exam_map_submit: "Submit succeed!It'll return to learning map",
    status_of_this: 'Status this exam',
    exam_status: 'Exam status',
    passing_score: 'Pass score',
    join_nums: 'Join nums',
    export_answer: 'Export answer',
    ios_export_tip: 'Apple computers do not support exporting, please visit the platform with a browser to perform exporting operations',
    my_scoring: 'My scoring',
    modify_score_tip: 'Are you sure to modify the score of the question?',
    modify_score_tip2: 'are you sure score for modifying question is ',
    modify_score_tip3: ' points?',
    exam_again: 'Exam again',
    screen_cut_time: 'screen cut time',
    screen_cut_time_tip: 'The system will force the paper to be handed in after the screen cut time',
    infinitely: 'infinitely',
    examPassword: 'exam password',
    examPasswordError: 'exam password error',
    PleaseNoteThatTheExamScreenCutTimeIsNthSeconds: 'Please note that the exam screen cut time is {0} S'
  },
  has_been_deleted: 'has been deleted',
  unpublished: 'unpublished',
  not_exist: 'not exist',
  no_permission: 'no permission',
  signup: {
    unlimited: 'Unlimited',
    people: '{0} people',
    training_introduction: 'Training introduction',
    training_arrangement: 'Training arrangement',
    do_need_review: 'Do you need to review',
    need: 'Need',
    no_need: 'No need',
    training_time: 'Training time',
    sign_time: 'Registration (opening) and closing time',
    sign_scope: 'registration scope',
    company_wide: 'Company-wide',
    contains_subdepartments: 'Contains subdepartments',
    project_introduction: 'Project Introduction',
    reason_for_ejection: 'Reason for rejection',
    signup_start_end: 'Sign up start： | Sign up end：',
    signup_end: 'Sign up has ended',
    start_sign_up: 'Start to sign up',
    cancel_sign_up: 'Cancel sign up',
    afresh_sign_up: 'Afresh sign up',
    subject_no_start: 'Cannot study until the subject has started',
    subject_has_expired: 'Subject has expired, unable to continue learning',
    sure_sign_up: 'Are you sure you want to sign up?',
    sure_cancel_sign_up: 'Are you sure to cancel sign up?',
    cancel_sign_up_success: 'Successful cancel sign up',
    cancel_sign_up_fail: 'Failed cancel sign up',
    registration_not_started: 'Registration not started',
  },
  cm_sign_up: 'Sign up',
  sign_up_details: 'Sign up details',
  lecturer_center: 'Lecturer center',
  lecturer: {
    level: 'Lecturer level',
    classify: 'Lecturer Classify',
    view_lecturer: 'View lecturer',
    basic_info: 'Basic info',
    online_courses: 'Online courses',
    offline_training: 'Offline training',
    honor: 'Honor',
    empty: 'Empty',
    lecturer_profile: 'Lecturer profile',
    expert_title: 'Expert title',
    areas_of_expertise: 'Areas of expertise',
    teaching_direction: 'Teaching direction',
    field_of_study: 'Field of study',
    other: 'Other',
    none: 'None',
    work_experience: 'Work experience',
    professional_rank: 'Professional rank',
    model_project_team_rank: 'Model project team rank',
    course_duration: 'Course duration',
    address: 'Address',
    not_exist: 'Sorry, the lecturer you are visiting does not exist.',
  },
  subject: {
    learners: 'Learners',
    people: '',
    time_range: 'Time range',
    no_study_time_limit: 'No study time limit',
    detail: 'Detail',
    arrange: 'Arrange',
    stages: '{0} stages',
    course_total: '{0} course in total',
    learning_progress_reaches: 'Learning progress reaches {0}%',
    learning_time: 'Learning time',
    study_certificate: 'Study certificate',
    intro: 'Subject intro',
    learning_progress: 'Learning progress',
    chapter_situation: 'Chapter situation',
    start: 'Start',
    course_not_exist: 'Sorry, the course topic you visited does not exist',
    learned: 'Learned {0}%',
    nth_people_have_learned: '{0} people have learned',
    special_introduction: 'Special introduction',
    recommended_subject: 'Recommended subject',
    course_no_permission: 'Sorry, you do not have the permission to study this course topic, please contact the administrator',
    overdue_tip: 'The project is overdue and cannot be continued',
    not_start_tip: "You can't study a topic until it starts",
  },
  activity: {
    type: 'Activity type',
    investigation: 'Investigation',
    evaluate: 'Evaluate',
    vote: 'Vote',
    total_activity: 'A total of | activities',
    assign_me_one: 'Assign me one {0}',
    has_expired: 'Activity has expired',
    not_started: 'Activity not started',
    participate: 'Participate',
  },
  to_be_done: 'To be done',
  cm_overdue: 'Overdue',
  cm_to: '~',
  shop: {
    my_exchange_record: 'My exchange record',
    integral_rule: 'Points exchange rules',
    exchange_number: 'Exchange per person',
    exchange_up_to: 'Exchange up to {0}',
    sure_exchange: 'Are you sure you want to exchange?',
    exchange_status: 'Exchange status',
    points_required: 'Points required',
    added_time: 'Added time',
    stock: 'Stock',
    commodity_detail: 'Commodity detail',
    commodity_stock: 'Commodity stock',
    pieces: 'pieces',
    people_exchange_num: 'This item is limited to {0} pieces per person',
    have_exchange_num: '{0} pieces have been exchanged',
    already_exchange: 'Already exchange',
    the_product_details: 'The product details',
    guess_you_like: 'Guess you like',
    integral_not_enough: 'Your points are not enough',
    table: {
      commodity_intro: 'Commodity intro',
      single_integral: 'Single integral',
      consumption_integral: 'Consumption integral',
      exchange_quantity: 'Exchange quantity',
      exchange_time: 'Exchange time',
    },
  },
  live: {
    to_be_completed_live: 'To be completed live',
    history_live: 'History Live',
    live_status: 'Live status',
    learning_status: 'Learning Status',
    required_study_time: 'Required study time',
    watch_time: 'Watch time',
    live_lecturer: 'Live lecturer',
    speaker: 'Speaker',
    speaker2: 'Speaker',
    participant: 'Participant',
    live_time: 'live time',
    live_detail: 'Live detail',
    live_not_started: 'Not Start',
    live_has_ended: 'Has Ended',
    view_replay: 'View Replay',
    no_exist: 'Live does not exist',
    start_client: 'Start client',
    web_live: 'Web live',
    start_live: 'Start Live',
    ios_tip: 'Apple computers do not support live broadcasting, please use Qiwei live broadcasting app to start live broadcasting',
    wxwork_tip: 'Please use enterprise WeChat to start the live broadcast',
    is_open_dingding_live: 'Is open dingding live...',
    view_live: 'View live',
    wxwork_tip2: 'Please use the enterprise WeChat to view the live broadcast',
    wxwork_tip3: 'Please use corporate WeChat to participate in the live broadcast',
  },
  cm_creator: 'Creator',
  train: {
    schedule: 'Face training schedule',
    progress: '',
    scan_code_attendance: 'Scan the code',
    click_scan_qr: 'Click here, quickly input attendance',
    signed_out: 'Signed out',
    attendance: 'Attendance',
    head: 'Head',
    not_exist: 'Face does not exist',
    on_camera_permission: 'Go to Settings to turn on camera permissions',
    lesson_hours: 'Lesson hours',
    lecturer: 'Lecturer',
    lesson_rating: 'Lesson rating',
    class_place: 'Class place',
    attendance_time: 'Attendance time',
    no_sign: 'No sign',
    no_sign_out: 'No sign out',
    course_detail: 'Details',
    course_comment: 'Commment',
    activity_attachments: 'Activity attachments',
    test_results: 'Test results',
    lecture_comments: 'Lecture comments',
    activity_photos: 'Activity picture',
    other_attachments: 'Other attachments',
    view_lecture_appraise: 'View lecture appraise',
    evaluation_lecturer: 'Evaluation Lecturer',
    view_course_appraise: 'View course appraise',
    evaluation_course: 'Wvaluation course',
    enter_meeting: 'Enter meeting',
    ask_for_leave: 'Ask for leave',
    cancel_leave: 'Cancel leave',
    approving_for_leave: 'Approving for leave',
    exam_results: 'Exam results',
    teacher_comment: 'Teacher comment',
    view_full: 'View full',
    sign_in_time: 'Sign in time',
    sign_out_time: 'Sign out time',
    not_rated: 'Not rated',
    lecturer_rating: 'Lecturer Rating',
    view_reviews: 'View reviews',
    evaluation: 'Evaluation',
    no_exist: 'Sorry, you visit to face-to-face does not exist.',
    please_use_enterprise_wechat_to_participate_in_meeting: 'Please use enterprise wechat to participate in meeting',
    is_open_dingding_meeting: 'Is open dingding meeting...',
    offline_grades: 'Offline grades',
    attendance_info: 'Attendance infor',
    sign_in_qr: 'Sign in QR',
    sign_out_qr: 'Sign out QR',
    grade_infor: 'Grade infor',
    pass_score: 'Pass score',
    entered: 'Entered',
    not_entered: 'Not entered',
    dynamic_q_r_code: 'Dynamic QR code, refreshed every {0} seconds',
    sign_in_status: 'Sign in status',
    sign_out_status: 'Sign out status',
    attendance_status: 'Attendance status',
    batch_operation: 'Batch operation',
    import_attendance: 'Import attendance',
    be_late: 'Be late',
    leave_early: 'Leave early',
    absent: 'Absent',
    attended: 'Attended',
    batch_set_attendance: 'Currently batch manual attendance settings for the selected {0} people',
    please_select_sign_in_time: 'Please select sign in time',
    please_select_sign_out_time: 'Please select sign out time',
    attendance_temp: 'Please click | Download Attendance Template | , upload the template to import attendance after editing the content',
    select_file: 'Select file',
    reselect: 'Reselect',
    drop_file_attendance: 'You can directly drag and drop files here to upload, supported file types: xls .xlsx',
    student: 'Student',
    please_select_a_student: 'Please select a student',
    checked_one: 'Content with at least one checkbox checked',
    wrong_file_type: 'Wrong file type, please select xls/xlsx file',
    please_upload_template_file: 'Please upload template file',
    operation_completed_without_importing_any_data: 'Operation completed without importing any data',
    import_nth_data: 'The operation is complete and {0} pieces of data have been imported successfully',
    attendance_template: 'Attendance_template.xlsx',
    has_been_evaluated: 'Has evaluated',
    not_evaluated: 'Not evaluated',
    evaluation_status: 'Evaluation status',
    detail: 'Detail',
    grading_time: 'Grading time',
    grade_status: 'Grade status',
    view_grade: 'View grade',
    enter_grades: 'Enter grades',
    enter_grades_for: 'Currently | entering grades for',
    grades: 'Grades',
    qualified_status: 'Qualified status',
    pass_status: 'Pass status',
    entered_successfully: 'Entered successfully',
    attachment_accept: 'Support pictures, documents, audio, video, compressed packages, upload up to 20 attachments, and a single file does not exceed 1G',
    attachment_accept_size: 'The file size cannot exceed 1G',
  },
  cm_contest: 'Contest',
  contest: {
    select_activity: 'Select',
    ba: '',
    not_open: 'Answer contest not open',
    stay_tuned: 'Stay tuned!',
    activity_desc: 'Activity desc',
    record: 'Records',
    my_gold_coin: 'My gold coin',
    qualifying: 'Qualifying ',
    qualifying_desc: '',
    foursome: 'Foursome ',
    foursome_desc: '',
    vs_desc: '',
    one_stop: 'One stop ',
    one_stop_desc: '',
    leaderboard: 'Leaderboard ',
    not_unlocked: 'Not unlocked',
    not_open_yet: 'Not open yet, stay tuned',
    opening_time: 'Opening time',
    vs: 'VS ',
    challenge_Record: 'Challenge Record',
    gold_coin: 'Gold coin',
    ranking_stars: 'Stars',
    have_not_participated: "Haven't participated in the competition yet",
    go_game: "Let's have a game",
    learning_from_the_old: 'learn from the old',
    quesition: 'Quesition | ',
    right: 'Right',
    wrong: 'Wrong',
    rule_desc: 'Rule desc',
    updated_in_real_time: 'Data updated in real time',
    no_one_on_list: 'No one has been on the list yet',
    go_answer: 'Go answer',
    not_yet_on_the_list: 'Not yet on the list',
    no_nth_list: 'No. | on the list',
    challenge_now: 'Challenge',
    ranking_rules: 'Ranking rules',
    leaderboard_ranking_rules_1: 'Display all ranking information of event participants.',
    leaderboard_ranking_rules_2: 'The higher the rank, the more stars, and the higher the winning rate, the higher the user ranking',
    leaderboard_ranking_rules_3: 'The leaderboard is updated in real time',
    leaderboard_ranking_rules_4: 'Note: Stars will only be added if you win the qualifying match',
    warm_tip_1: 'The user successfully matches the opponent and enters the answering process. Abnormal situations such as mobile phone failure and poor network will affect the score, ranking and gold coins of the game. It is recommended to check the mobile phone device and participate in answering questions under a good network environment',
    entry_coins: 'Entry coins',
    win_reward: 'Win reward',
    failure_reward: 'Failure reward',
    competition_rules: 'Competition rules',
    qualifying_competition_rule_1: 'Match 1 user online in each game, and start answering after the match is successful',
    qualifying_competition_rule_2: '{0} points for each question, {1} seconds for each question',
    qualifying_competition_rule_3: 'There are a total of {0} questions in each game, two people play against each other, the one with the higher total score wins, and a star will be rewarded for winning',
    qualifying_competition_rule_4: 'For each game failure, a star will be deducted | Lose every game, no stars will be dropped',
    qualifying_competition_rule_5: 'Answer records can only be generated after all users in each game have finished the game; users who quit midway and end early should check the answer records after the game is over',
    qualifying_ranking_rules_1: 'Users with more stars rank higher on the leaderboard',
    warm_tip_2: 'If you swipe left on your iPhone, you cannot exit the answering process normally. Please click the back button in the upper left corner of the page to leave the arena',
    game_not_open: 'Game not open',
    enough_gold_coins: 'Not enough gold coins to participate!',
    conins_for_place: 'First place {0} in gold coins game, second place {1}, third place {2}, fourth place {3}',
    challenger: 'Challenger',
    good_network: 'Good network',
    poor_network: 'Poor network',
    start_game: 'Start',
    gold_coins_today: 'Gold coins today ',
    foursome_competition_rule_1: 'Match 4 users online in each round, and start answering after the match is successful',
    foursome_competition_rule_2: '+{0} points for each correct answer, and no point reduction for wrong answers. If you get 100 points first, you will win, and you will be rewarded with gold coins',
    foursome_competition_rule_3: 'Each game lasts up to 10 minutes, and if no player reaches 100 points within 10 minutes, all players will lose',
    foursome_competition_rule_4: 'Answer records can only be generated after all users in each game have finished the game; users who quit midway and end early should check the answer records after the game is over',
    invite_friends: 'Invite friends',
    compete_with_friends: '',
    random_match: 'Random match',
    fight_passersby: '',
    vs_competition_rule_1: 'Click on the empty space to invite a friend or user (randomly) to participate in the battle, the master has the right to start',
    vs_competition_rule_2: 'Daily gold reward {0} rounds, win +{1} gold, lose +{2} gold',
    vs_competition_rule_3: 'Note: Participating in a two-player battle will not add stars, and the results will not be included in the leaderboard',
    wrong_leave_right_continue: 'Wrong leave，Right continue',
    matching: 'Matching...',
    successful_match: 'Successful match',
    wait_other_answer: 'Wait answer',
    highest_scorer: 'Highest scorer',
    first_100: 'First 100',
    all_right: 'All right',
    will: 'Will',
    readying: 'Readying',
    start: 'Start',
    quesition_num: 'Quesition {0}',
    consecutive_right: 'Right x {0}',
    congratulations: 'Congratulations',
    wrong_answer: 'Wrong',
    congratulations_to_you: 'Congratulations to you',
    get_win: 'Get {0} win',
    unfortunately: 'Unfortunately',
    win_next_time: "Don't get discouraged, win next time",
    challenge_success: 'Challenge success',
    challenge_failed: 'Challenge failed',
    escaped: 'Escaped',
    leave_page: 'Leave this page after {0} seconds or click anywhere',
    victory: 'Victory',
    keep_on: 'Keep on',
    review_answer: 'Review',
    challenge_again: 'Challenge again',
    choose_colleagues: 'Choose colleagues',
    recently_played: 'Recently played',
    company_colleagues: 'Company colleagues',
    send_invite: 'Send invite',
    challenge_ends: 'Challenge ends',
    answer_quesitions_time: 'Answer | questions this time',
    highest_answer_quesitions: 'The highest answer is {0} questions',
    gold_coin_limit: 'Gold coins have reached the limit',
    end_the_game: 'End the game',
    ranking_result_tip_1: 'Priority score ranking',
    ranking_result_tip_2: 'If the scores are the same, the one with the shortest answering time will win',
    ranking_result_tip_3: 'The score is the same as the time, whoever answers the last question first wins',
    ranking_result_tip_4: 'If you run away and disconnect from the last question, the opponent wins, no matter how high or low the score is',
    back_tip_1: 'Are you sure you want to leave the ring?',
    back_tip_2: 'Are you sure you want to quit? If you quit halfway, it will be considered a failure.',
    continue: 'Continue',
    quit: 'Quit',
    insufficient_gold_coins: 'Insufficient gold coins',
    rank_not_unlocked: 'Not unlocked',
    please_select_colleagues: 'Please select colleagues to invite',
    sure_invite_select: 'Are you sure to invite the selected people?',
    invite_you_participate: '{0} invite you to participate in VS',
    the_battle_over: 'The battle is over, there are no more questions!',
    init_rule: {
      tip_1: 'The priority to get 100 points is to win',
      tip_2: 'Correct answer + {0} point, wrong answer no deduction',
      tip_3: 'Online confrontation, the able one wins',
      tip_4: 'Resource loading, start now',
      tip_5: '{0} points per question',
      tip_6: '{0} questions per game',
    },
  },
  bbs: {
    no_more: 'No more',
    please_enter_title_author: 'Please enter a title/author',
    post: 'Post',
    ask: 'Ask',
    article: 'Article',
    my_circle: 'My circle',
    create_circles: 'Create circle',
    find_circle: 'Find circle',
    topic: 'Topic',
    topic_2: 'Topic',
    member: 'Member',
    top_zone: 'Top Zone',
    top: 'Top',
    essence: 'Essence',
    admin: 'Admin',
    circle_owner: 'Owner',
    latest_content: 'latest',
    recommended_content: 'Recommended',
    hot_content: 'Hot',
    latest_discussion: 'Discussion',
    only_view_owner: 'View owner',
    anonymous_user: 'Anonymous User',
    invited: 'Invited {0}',
    invited_2: 'Invited',
    been_invited_1: '{0} invited {1} people',
    been_invited_2: '{0} been invited',
    form: 'Form {0}',
    internal_link: 'internal link',
    external_link: 'external link',
    attachment: 'Attachment {0}',
    video: 'Video {0}',
    reward_answer_integral: 'Reward answer {0} Integral',
    publish: 'Publish',
    file_status_1: 'not converted | pending converted',
    file_status_2: 'converting | conversion failed',
    i_will_answer: 'Asnwer',
    i_will_comment: 'Comment',
    leave_app_tip: 'You are about to leave this page, please pay attention to account security',
    zip_not_preview: 'zip files do not support preview',
    join_corcle_like: 'Join the circle to like',
    please_publish: 'Please publish first',
    join_circle_view_detail: 'Join the circle to view details!',
    join_circle_can: 'Join the circle to answer | Join the circle to comment',
    my: {
      publish: 'My publish',
      draft: 'My draft',
      follow: 'My follow',
      collect: 'My collect',
      comment: 'Comment my',
      answer: 'Answer my',
      like: 'Like my',
      invited: 'Invited my',
    },
    published: 'Published',
    unpublished: 'Unpublished',
    invite_me_follow: 'invite me to follow {0}',
    my_answer: 'My answer',
    my_comment: 'My comment',
    liked: 'liked {0}',
    liked_2: 'Liked',
    this: 'this {0}',
    i_manage: 'I manage',
    waiting_review: 'Waiting review',
    apply_join: 'Apply join',
    circle_profile: 'Circle profile',
    num_members: '{0} members',
    after_join: 'After joining, you can browse exciting content',
    member_manage: 'Member manage',
    invite_people: 'Invite people',
    disband_circle: 'Disband circle',
    quit_circle: 'Quit circle',
    invite_you_join_circle: 'Invite you to join the study circle',
    scan_code_enter_circle: 'Scan the code to enter the circle immediately',
    link_browser: 'Or enter the link in the browser',
    copy_link: 'Copy link',
    save_card: 'Save invitation card',
    disband_circle_tip: 'Are you sure to disband the circle?',
    disbanded_successfully: 'Disbanded successfully!',
    disbanded_failed: 'Disbanded failed!',
    quit_circle_tip: 'Are you sure to quit the circle?',
    quit_successfully: 'Quit successfully!',
    quit_failed: 'Quit failed!',
    copy_successfully: 'Copy successfully',
    back_circle: 'Go Back',
    please_enter_name_account_depart: 'Please enter name account depart',
    topics: 'Topics',
    inviter: 'Inviter',
    join_time: 'Join time',
    apply_time: 'Apply time',
    process_time: 'Process time',
    invite_time: 'Invite time',
    process_opinion: 'Process opinion',
    remove: 'Remove',
    set_admin: 'Set admin',
    cancel_admin: 'Cancel admin',
    agree: 'Agree',
    reject: 'Reject',
    has_invited: 'Has invited',
    sure_agree: 'Are you sure you agree?',
    sure_reject: 'Are you sure you want to decline?',
    please_enter_review: 'Please enter review comments (optional)',
    sure_remove: 'Are you sure you want to remove?',
    invite_successful: 'Invite successful',
    has_joined: 'Has joined',
    pending_review: 'Review',
    has_rejected: 'Has rejected',
    selected: 'Selected',
    select_most_people: 'Select at most {0} people',
    person_is_select: 'This person is selected',
    please_enter_title_name_content: 'Please enter title/name/content',
    follow: 'Follow the topic | follow',
    unfollow: 'Unfollow | unfollow',
    sure_follow_topic: 'Are you sure you want to {0} the topic?',
    follow_successfully: 'Successfully {0}',
    follow_failed: 'Failed {0}',
    search_you_interested: 'Search for what you are interested in...',
    search_history: 'Search history',
    view_all: 'View all',
    like_2: 'Like',
    quesition: 'Quesition',
    follow_quesition: 'Follow',
    featured_answer: 'Featured',
    comment_time: 'Comment time',
    comment_hot: 'Comment hot',
    answer_time: 'Answer time',
    answer_hot: 'Answer hot',
    post_detail: 'Post detail',
    ask_detail: 'Q&A detail',
    article_detail: 'Article detail',
    global_top: 'Global top',
    set_global_top: 'Set global top',
    circle_top: 'Circle top',
    set_circle_top: 'Set circle top',
    cancel_top: 'Cancel top',
    refined: 'Refined',
    cancel_refined: 'Cancel refined',
    close_quesition: 'Close quesition',
    quesition_has_close: 'This question has been closed',
    edit: 'Edit',
    delete: 'Delete',
    sure_delete: 'Are you sure you want to delete?',
    i_want: 'I want',
    only_owner_can: 'Only the owner can {0}',
    only_owner_admin_can: 'Only the owner/admin can {0}',
    adopted: 'Adopted',
    featured_recommended: 'Recommend',
    replied: 'Replied {0}',
    in_total: '{0} in total',
    view_replied: 'View {0} replies',
    adopt: 'Adopt',
    recommend: 'Recommend',
    adpoted_cannot_del: 'Adpoted replies cannot be deleted',
    after_adpot_cannot_cancel: 'Cannot be canceled after adoption, are you sure you want to adopt?',
    adopted_successfully: 'Adopted successfully',
    after_recommend_cannot_cancel: 'After recommending, it cannot be canceled. Are you sure you want to recommend?',
    recommended_successfully: 'Recommended successfully',
    say_you_want: 'Say what you want',
    remind_who_watch: 'Remind who watch',
    remind_who_watch_tip: "People who {'@'} will receive a message reminder",
    anonymous_reply: 'Anonymous reply',
    anonymous_answer: 'Anonymous answer',
    anonymous_comment: 'Anonymous comment',
    reply_detail: 'Reply detail',
    join_now: 'Join',
    enter_circle: 'Enter circle',
    sure_agree_join: 'Are you sure you agree to join?',
    please_enter_topic_name: 'Please enter a topic name',
    max_choose_topic: 'Choose at most {0}',
    group: {
      cover: 'Circle cover',
      cover_tip: 'suggest size 130 * 130, file no more than 1 mb of jpg, jpeg, png images',
      please_upload_select: 'Please upload or select a cover',
      name: 'Circle name',
      please_enter_name: 'Please enter a circle name',
      intro: 'Circle intro',
      intro_p: 'Please enter circle profile',
      owner: 'Circle owner intro',
      owner_p: 'Please enter owner profile',
      access_permission: 'Access permission',
      join_limit: 'Join limit',
      browse_settings: 'Browse settings',
      post_subject_permission: 'Post subject Permissions',
      comment_reply_permission: 'Comment answer reply permission',
      member_info: 'Member info',
      anonymous_settings: 'Anonymous settings',
      permission_settings: 'Permission settings',
      allow_anonymous_post_subject: 'Allow anonymous posting of topics',
      allow_anonymous_comment_answer_reply: 'Allow anonymous comments/answers/replies',
      allow_anonymous_all: 'Allow anonymous comments/answers/replies',
      no_anonymity_allowed: 'No anonymity allowed',
      not_save_leave: 'The content is not saved, whether to exit',
      created_successfully: 'Created successfully, Jumping...',
      created_failed: 'Created failed',
      public_circle: 'Public circle',
      public_circle_tip: 'All users can access',
      private_circle: 'Private circle',
      private_circle_tip: 'Do not show in discovery circles',
      no_need_review: 'No need review',
      no_need_review_tip: 'All users allowed to join',
      need_review: 'Need review',
      need_review_tip: 'All users need to be reviewed to join the circle',
      specify_user_to_join: 'Specify user to join',
      specify_user_to_join_tip_2: "Other users can't apply to join",
      specify_user_to_join_tip: 'Can only be invited by circles and circle admins',
      allow: 'Allow unsubscribed users to preview some topics',
      allow_tip: 'Allow unjoined users to browse the highlights or the latest topics three times',
      not_allowed: 'Not allowed',
      not_allowed_tip: 'Unjoined users are not allowed to browse subtopics',
      all_people: 'All people',
      circle_owner_only: 'Circle owner only',
      owner_admin: 'Owner + Admin',
      open_member_list: 'Open member list',
      open_member_list_tip: 'After checking, the member list will be displayed on the circle home page',
      closed_member_list: 'Closed member list',
      allow_anonymity: 'Allow anonymity',
      contact_owner_join_circle: 'You have not joined this circle and this circle is a private circle, please contact the circle owner to join the circle',
      the_circle_is_created: 'Congratulations, the circle is created!',
      the_circle_is_created_tip: 'From here, invite people to join your circles',
      the_circle_is_created_tip_2: 'As a circle owner, please publish the first topic of this circle',
    },
    addpost: {
      title: 'Title',
      please_enter_title: 'Please enter title',
      title_less_tip: 'The title cannot be less than {0} characters',
      content: 'Content',
      text: 'Text',
      please_enter_text: 'Please enter text',
      text_less_tip: 'The text cannot be less than {0} characters',
      text_more_tip: 'The text cannot be more than {0} characters',
      link: 'Link',
      allow_download: 'Allow download(only pc can do)',
      upload_cover: 'Upload cover',
      upload_cover_tip: 'Suggested size: 260*150; the size should not exceed 1M',
      upload_image: 'Upload image',
      select_circle: 'Select a circle (required)',
      reward_points: 'Reward points',
      post_anonymously: 'Anonymous post',
      add_link: 'Add link',
      please_enter_link: 'Please enter link',
      link_start: 'Please start the link with http:// or https://',
      link_tip: 'Please note that you are sharing an external link, and all information submitted by members on third-party sites will be enforced and responsible for that site in accordance with its privacy, confidentiality, and security policies.',
      select_bonus_points: 'Select Bonus Points',
      please_enter_reward_points: 'Please enter reward points',
      available_points: 'Available points',
      select_image: 'Select image',
      select_video: 'Select video',
      content_less_tip: 'The content cannot be less than {0} characters',
      post: 'Post',
      ask: 'Ask',
      article: 'Article',
      failed_get_data: 'Failed to get data',
      url_format_incorrect: 'Url format is incorrect',
      topic: 'Topic',
      select_topic: 'Select topic',
      click_enter_create_topic: '，Or click Enter to create a new topic',
      choose_up_topics: 'Choose up to {0} topics',
      this_topic_is_selected: 'This topic is selected',
      less_word_topic: 'The topic should not be less than {0} words',
      input_positive_integer: 'Please input a positive integer',
      insufficient_points: 'Insufficient points',
      circle: 'Circle',
      please_select_circle: 'Please select a circle!',
      published_successfully: 'Published successfully',
      publish_failed: 'Publish failed',
      choose_person: 'Choose person',
      please_choose_person: 'Please choose person',
      invite_up_to_nth_people: 'Invite up to {0} people',
      picture_tip: 'I have uploaded {0} pieces, and I can upload {1} pieces at most, and support jpg, jpeg, png, gif formats, and each piece does not exceed {2}',
      video_tip: 'Please upload the video below {0} | Support upload MP4, AVI, MOV, WMV, RM(VB), FLV format',
      file_tip: '{0} pieces have been uploaded, and {1} pieces can be uploaded at most | supporting pdf, ppt, pptx, doc, docx (single document less than 100MB), zip (single attachment less than 1G)',
      parse: 'Parse',
      delete_link_tip: 'Are you sure to delete the link?',
      save_draft: 'Save draft',
    },
    not_open_join: 'This study circle is not open to join yet!',
    sure_join: 'Are you sure you want to join?',
    application_successful: 'Application successful!',
    application_failed: 'Application failed!',
    has_application_wait: 'Already applied, please wait for approval!',
    nth_people: '...{0} people',
    click_download: 'Download',
    add_post: 'Post',
    add_ask: 'Ask',
    add_article: 'Article',
    not_post_tip: 'Content distribution function has been closed, if you want to use, please contact administrator!',
    nth_answer: '{0} answers',
    confirm_cancel: 'Confirm cancel',
    confirm_cancel_tip: 'The current operation has not been saved. Are you sure to cancel?',
    invite_members: 'Invite members',
    invite_members_tips: 'please enter your name or account to invite',
    agree_to_join: 'Agree to join',
    have_applied: 'Have applied',
    best_sharer: 'Best sharer',
    related_osts: 'Related Posts',
    member_list: 'Member list',
    please_enter_account_name_department_to_search: 'Please enter account/name/department to search',
    batch_remove: 'Batch remove',
    remove_selected_nth_people: 'Are you sure you want to remove the selected {0} people',
    click_answer: 'click answer',
    click_comment: 'click comment',
    toggle_follow: 'follow | unfollow',
    toggle_like: 'like | cancel like',
    toggle_collect: 'collect | cancel collect',
    all_comments: 'All comments',
    all_answers: 'All answers',
    by_time: 'Time',
    mentioned: 'Mentioned',
    nth_replies: '{0} replies',
    hot_topic: 'Hot topic',
    are_you_sure_to_delete: 'Are you sure to delete?',
    post_count: 'Posts',
    quesition_count: 'Quesitions',
    article_count: 'Articles',
  },
  hot_sign_up: 'Hot sign up',
  loading: 'loading...',
  has_send_to: 'Has send to',
  login_successful: 'Login successful',
  login_failed: 'Login failed',
  login_failed_not_admin: 'Login failed; the account has not been granted administrative rights',
  confirm_login_you: 'Please confirm to log in with your account',
  login_confirm: 'Confirm',
  sorry: 'Sorry',
  page_not_exist: 'The page you are visiting does not exist',
  update_tip: 'Update tip',
  update_content: 'The new version is ready, do you want to restart the application?',
  new_version: 'There is a new version',
  new_version_content: 'The new version is already online~ Please delete the current applet and search again to open it~',
  all_task: 'All task',
  cm_pre: 'Pre',
  cm_next: 'Next',
  face: {
    pending_face: 'Pending face',
    history_face: 'History face',
    please_enter: 'Please enter name',
    type: 'Type',
    state: 'Status',
    online: 'Online',
    offline: 'Offline',
    mix: 'Mix',
    study_state: 'Status',
    face_time: 'Face time',
    address: 'Address',
  },
  view_detail: 'View detail',
  no_more: 'No more',
  load_more: 'Load more',
  please_select_data: 'Please select data',
  issued_independently: 'Issued independently',
  teaching: {
    helping_task: 'Helping task',
    learning_track: 'Learning track',
    table: {
      map_name: 'Study map name',
      schedule_completion: 'Schedule completion',
      evaluate_students: 'Evaluate students',
      summarize: 'Summarize',
      operate: 'Operate',
      name_account: 'Name(account)',
      department: 'Department',
      resource_name: 'Resource name',
      status: 'Status',
      completion_standard: 'Completion standard',
      finish: 'Finish',
      in_progress: 'In progress',
      overdue: 'Overdue',
      completion_rate: 'Completion rate',
      pass_rate: 'Pass rate',
      participation_rate: 'Participation rate',
      name: 'Name',
      account: 'Account',
      position: 'Position',
      whether_to_pass: 'Whether to pass',
      learning_progress: 'Learning progress',
      study_completed: 'Study completed',
      pass_num: 'Pass num',
      online_study_time: 'Online study time',
      激活时间:'Activation time',
      到期时间:'Expiration time',
      exam_time: 'Exam time',
      stage_progress: 'Stage progress',
    },
    arranged: 'Arranged',
    should_arrange: 'Should arrange',
    rated: 'Rated',
    should_evaluate: 'Should evaluate',
    should_submit: 'Should submit',
    arrange_plan: 'Arrange plan',
    evaluate_students: 'Evaluate students',
    review_summary: 'Review summary',
    please_enter_name_account: 'please enter name/account',
    not_arranged: 'Not arranged',
    pending_feedback: 'Pending feedback',
    arrange_a_helping_program: 'Arrange a helping program',
    mentor_program: 'Mentor program',
    arrange_help_tip: 'Student Feedback: Student Confirmed',
    arrange_help_input_tip: 'Please enter the mentoring plan you have arranged for the trainee, such as the training courses that the trainee should participate in during the mentoring period, the business skills and abilities that should be mastered and improved, the work to be undertaken, the expected goals, etc.',
    excellent: 'Excellent',
    qualified: 'Qualified',
    unqualified: 'Unqualified',
    submit_summary_complete: 'Submit summary complete',
    approved_qualified: 'Approved qualified',
    summary_review: 'Summary review',
    require: 'Require',
    total_score: 'Total score',
    passing_score: 'Passing score',
    submitter: 'Submitter',
    summary_content: 'Summary content',
    please_enter_a_comment: 'Please enter a comment',
    fill_score: '{0} score',
    num_score_pass: '{0} score pass',
    overdue: 'Overdue',
  },
  cm_view: 'View',
  demand: {
    call_for_infor: 'request for information',
    please_enter_the_name_of_the_demand_collection: 'Please enter the name of the demand collection',
    table: {
      solicitation_name: 'Solicitation name',
      solicitation_year: 'Solicitation year',
      collection_time: 'Collection time',
      status: 'Status',
      operate: 'Operate',
    },
    call_for_requests: 'Call for requests',
    add_demand: 'Add demand',
    collection_has_not_started_yet: 'collection has not started yet',
    solicitation_has_ended: 'solicitation has ended',
    submit_record: 'Submit record',
    form: {
      year: 'Year',
      training_name: 'requirement name',
      training_name_p: 'please enter requirement name',
      training_form: 'Training form',
      training_form_p: 'Please select training format',
      holding_time: 'Holding time',
      holding_time_p: 'Please select a time',
      requirements_catalog: 'Requirements catalog',
      requirements_catalog_p: 'Please select the required category',
      training_budget: 'Training budget',
      training_budget_p: 'Please enter training budget',
      content_of_training_needs: 'Content of training needs',
      content_of_training_needs_p: 'Please enter training requirements',
      reason_for_training_need: 'Reason for training need',
      reason_for_training_need_p: 'Please enter reason for training need',
    },
    requirement_reporting_record: 'requirement reporting record',
    internal_training: 'Internal training',
    send_out_training: 'Send out training',
    add_save_tip: 'The submission is successful, do you want to continue to submit the request?',
    batch_reporting: 'Batch reporting',
    batch_delete: 'batch deletion',
    budget: 'Budget',
    report_tip: 'please select requirement you want to report',
    sure_repors: 'are you sure you want to batch report selected demand data?',
    sure_repor: 'are you sure you want to report demand data?',
    del_tip: 'please select requirement you want to delete',
    sure_dels: 'are you sure you want to batch delete selected demand data?',
    sure_del: 'are you sure you want to delete this requirement data?',
    report_status: 'report status',
    reporting_time: 'reporting time',
    reported: 'reported',
    not_reported: 'not reported',
    requirement_information: 'requirement information',
    reporting_information: 'reporting information',
    develop_information: 'develop information',
    reportperson: 'reportperson',
    established: 'established',
    not_formulated: 'not formulated',
    set_time: 'set time',
    training_plan_name: 'training plan name',
    reporting_department: 'reporting department',
    reporting: 'reporting',
    yuan: '',
    please_enter_name: 'please enter name (within 50 words)',
    please_enter_requirements: 'please input training requirement content (within 500 words)',
    please_enter_reason: 'please enter training requirement reason (within 500 words)',
    please_enter_budget_cost: 'please input budget cost < 99999',
  },
  overdue: 'Overdue',
  cm_add: 'Add',
  cm_operate: 'Operate',
  confirm_prompt: 'Confirm prompt',
  hottest: 'Hottest',
  current_position: 'Current position',
  please_enter_keywords: 'Please enter keywords',
  cm_refresh: '',
  sorry_you_visited: 'Sorry, {0}',
  cm_door: '',
  cm_field: '',
  sure_submit: 'Are you sure to submit?',
  no_attachment: 'No attachment',
  knowledge: {
    knowledge_catalog: 'Knowledge catalog',
    no_catalog: 'No catalog',
    knowledge_base_of: 'Knowledge base of',
    total_knowledge: 'A total of | knowledge',
    knowledge_content: 'knowledge content',
    pic: 'Pic',
    big_pic: 'Pic',
    list: 'List',
    intro: 'Intro',
    sharer: 'Sharer',
    table: {
      file_name: 'File name',
      file_type: 'File type',
      downloads: 'Downloads',
      likes: 'Likes',
      views: 'Views',
      comments: 'Comments',
      sharer: 'Sharer',
      upload_time: 'Upload time',
    },
    no_exist: 'Sorry, the knowledge you visited does not exist',
  },
  the_version_is_too_low_please_upgrade: 'The version is too low, please upgrade',
  cm_views: 'Views',
  cm_account: 'Account',
  cm_modify: 'Modify',
  cm_start: 'Start',
  cm_end: 'End',
  system_prompt: 'System prompt',
  pic: '',
  cm_ok: 'Ok',
  teacher: {
    calendar: 'Calendar',
    online_courses: 'Online',
    offline_class_schedule: 'Offline',
    live_class_schedule: 'Live',
    lecturer_file: 'Profile',
    lecturer_application_record: 'lecturer application record',
    student_area: 'Student',
    total_today_nth_lesson: 'Total today | lessons',
    banner_tip: 'Enterprise internal training lecturer resources, you can quickly understand the strength of enterprise teachers',
    lecturer_infor: 'Lecturer infor',
    application_record: 'Application record',
    historical_tenure: 'Historical tenure',
    honor: 'Honor',
    avatar_tip: 'The image upload ratio is 3:4, and the best upload resolution is 300*400',
    lecturer_level: 'Lecturer level',
    lecturer_level_p: 'Please select lecturer level',
    name: 'Name',
    lecturer_classification: 'Lecturer Classification',
    lecturer_classification_p: 'Please select lecturer classification',
    sex: 'Sex',
    man_woman: 'Man | Woman',
    mobile: 'Mobile',
    email: 'Email',
    tel: 'Tel',
    position: 'Position',
    birthday: 'Birthday',
    inside: 'inside',
    external: 'external',
    honor_description: 'honor description',
    lecturer_intro: 'Lecturer intro',
    lecturer_intro_p: 'Please enter lecturer intro',
    motto: 'Motto',
    motto_p: 'Please enter motto',
    teachingDirection: 'Teaching direction',
    teachingDirection_p: 'Please enter teaching direction',
    expertAreas: 'Expert areas',
    expertAreas_p: 'Please enter expert areas',
    expertTitle: 'Expert title',
    add_title: 'Add',
    researchAreas: 'Research areas',
    researchAreas_p: 'Please enter research areas',
    workExperience: 'Work experience',
    workExperience_p: 'Please enter work experience',
    others: 'Others',
    others_p: 'Please enter others',
    upload_avatar: 'Upload avatar',
    crop: 'Crop',
    professional_rank: 'Professional rank',
    professional_rank_p: 'Please enter  professional rank',
    under_review: 'Under review',
    rejected: 'Rejected',
    apply_level: 'Apply level',
    attachment: 'Attachment',
    apply_status: 'Apply status',
    apply_time: 'Apply time',
    take_effect: 'Take effect',
    edit: 'Edit',
    newly_hired: 'Newly hired',
    renew: 'Renew',
    promotion: 'Promotion',
    downgrade: 'Downgrade',
    fired: 'Fired',
    honor_time: 'Honor time',
    please_enter_a_certificate_name: 'Please enter a certificate name',
    review_rejected: 'Review rejected',
    review_pass: 'Review pass',
    assessment_level: 'Assessment level',
    result: 'Result',
    evaluation_date: 'Evaluation date',
    department: 'Department',
    unit: 'unit',
    lecturer_courseware: 'Lecturer courseware',
    lecturer_courseware_p: 'Please upload courseware',
    lecturer_application_is_under_review_please_be_patient: 'lecturer application is under review, please be patient...',
    lecturer_application_information_audit_rejected_please_again: 'lecturer application information audit rejected, please again',
  },
  not_over: 'Not over',
  PracticeResults: "practice results",
  PracticeDuration: "practice time",
  PracticeStatus: "practice state",
  ResultAnalysis: "result analysis",
  MonitoringDetails: "monitoring details",
  AnalysisRadar: "analysis radar",
  dialogue_review: 'dialogue review',
  cm_customer: 'customer',
  cm_sales: 'sales',
  cm_face_matching: 'face matching',
  cm_are_you_serious: 'are you serious',
  cm_yes: 'yes',
  cm_no: 'no',
  chapter_completion: 'chapter completion',
  homework_after_class: 'homework after class',
  after_class_exam: 'after class exam',
  after_class_assessment: 'after class assessment',
  class_assessment: 'course evaluation',
  star_review: 'star review',
  cm_learning_data: 'learning data',
  cm_entry_time: 'Entry time',
  cm_export: 'Export',
  learningArchive: {
    StudyArchives: "study archives",
    TotalNumberOfLogins: "Number of logins",
    OnlineDuration: "Online duration",
    NumberOfSelfStudyCourses: "Number of courses",
    NumberOfTrainingPrograms: "Number of project",
    SelfStudyCourse: "Self-study course",
    table: {
      serial: 'Serial',
      courseName: 'Course name',
      courseDuration: 'Course duration',
      learningDuration: 'Learning duration',
      studyProgress: 'Progress',
      startStudyTime: 'Start study time',
      finalStudyTime: 'Final study time',
    },
  },
  cm_ge: '',
  downloadSuccessfully: 'Download successfully',
  networkErrMsg: 'We have detected that your current network environment is relatively poor. Continuing to learn will affect the statistics of your learning records. We suggest that you exit the learning interface!',
  getScoreWayTip: 'Rules for Obtaining Points',
  ScoreTimeWayTip: 'Score points based on the total number of times and earn {scores} points once. A maximum of {times} points can be earned in total.',
  ScoreTimeWayTip2: 'Score points based on the total number of times and earn {scores} points once.Unlimited number of times',
  ScoreDayWayTip: 'Score points based on the number of times per day, earn {scores} points through one pass per day, and earn points up to {times} times per day.',
  ScoreDayWayTip2: 'Score points based on the number of times per day, earn {scores} points through one pass per day.Unlimited number of times',
  ScoreNumberWayTip: 'score based on number of correct answers, earn {scores} points for each correct answer',
  FaceToFaceTeachingCannotBeEvaluated: 'face-to-face teaching has not yet started and cannot be evaluated',
  downloadCenter: 'download center',
  PleaseEnterFileName: 'please enter file name',
  downloadCenterTips: 'file is currently being generated. please go to download center to download it later',
  expiresAfter3Days: "(expires after 3 days)",
  generating: "generating",
  LastPage: "last page",
  NextPage: "next page",
  StartDownloading: "start downloading",
  InTotal: "{total} in total",
  LB_Error_ExamHasTraining: "Start training later",
  TrainingHasEnded: "Training has ended",
  TrainingInProgress: "Training in progress",
  total_class_hours: "total class hours",
  remainder: 'Remainder',
  expanded: 'Expanded',
  unexpanded: 'Unexpanded',
  expandedAll: 'Expanded all',
  unexpandedAll: 'Unexpanded all',
  informationCollection: 'Information collection',
  charactersOrLess: '({0} characters or less)',
  IdCard: "IdCard",
  passport: "passport",
  TaiwanPass: "TaiwanPass",
  HongKongMacaoPass: "HongKongMacaoPass",
  city: "City",
  ActivityInformationCollection: 'Activity infor collect',
  ExamInformationCollection: 'Exam infor collect',
  ParticipationInExams: 'Participation in exams',
  FileNotPreview: '{type} file not supported for preview',
}