import "core-js/stable";
import "regenerator-runtime/runtime";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from './locale/i18n'

import 'viewerjs/dist/viewer.css?v=1.0.1'
import VueViewer from 'v-viewer'
import infiniteScroll from 'vue3-infinite-scroll-better'

import 'reset-css/reset.css';
import 'ant-design-vue/dist/antd.less';
import antCompoents from './utils/antCmpoents';
import useIcons from './utils/icons';
import JSEncrypt from './utils/jsencrypt';


// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()

const app = createApp(App);
antCompoents(app);
useIcons(app);
JSEncrypt(app);

app.use(store)
    .use(router)
    .use(i18n)
    .use(VueViewer)
    .use(infiniteScroll)

app.mount("#app");
  
